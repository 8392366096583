import * as yup from 'yup';
import usersIcon from 'assets/images/icons/users-green.svg';
import { CallAPI } from 'actions/General';
import formGreyIcon from 'assets/images/icons/form-grey.svg';
import formIconWhite from 'assets/images/icons/form-green.svg';
import userIcon from 'assets/images/icons/users-green.svg';
// import issueIcon from 'assets/images/icons/issues-grey.svg';
// import issueIconWhite from 'assets/images/icons/issues-green.svg';

export const tabList = [
  {
    id: 1,
    title: 'Details',
    isSelected: true,
    breadcrumbValue: { icon: userIcon, items: ['Users', 'User Name', 'User Activity', 'Details'] },
  },
  {
    id: 2,
    title: 'Activity',
    isSelected: false,
    breadcrumbValue: { icon: userIcon, items: ['Users', 'User Name', 'User Activity', 'Activity'] },
  },

  {
    id: 3,
    title: 'Access',
    isSelected: false,
    breadcrumbValue: { icon: userIcon, items: ['Users', 'User Name', 'User Activity', 'Access'] },
  },
];
export const addUserTabList = [
  {
    id: 1,
    title: 'tabs_add_user_by_detail',
    breadcrumbValue: {
      icon: usersIcon,
      items: ['sidebar_users', 'button_add_user', 'tabs_add_by_detail'],
    },
  },
  {
    id: 2,
    title: 'tabs_link_qr_code',
    breadcrumbValue: {
      icon: usersIcon,
      items: ['sidebar_users', 'button_add_user', 'tabs_link_qr_code'],
    },
  },
  // { id: 3, title: 'Import' },
];
export const inviteUserSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  countryCode: yup.string().required('Country code is required'),
  mobileNumber: yup.string().required('Phone number is required'),
  email: yup.string().email().notRequired('Email address is required'),
  department: yup.string().notRequired('Department is required'),
  branch: yup.string().notRequired('Branch is required'),
  title: yup.string().notRequired('Title is required'),
  employmentType: yup.string().notRequired('Employment type is required'),
  personalIdNumber: yup.string().notRequired('Personal Id number is required'),
  personalIdExpiry: yup.string().notRequired('Personal Id expiry is required'),
  nationality: yup.string().notRequired('nationality is required'),
  birthDate: yup.string().notRequired('Date of birth is required'),
});

export const bulkInviteUserSchema = yup.array().of(inviteUserSchema);

export const updateContactSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup.string().email().notRequired(),
});

export const UserTabsList = [
  {
    id: 1,
    title: 'tabs_users',
    value: 'user',
    breadcrumbValue: { icon: usersIcon, items: ['tabs_users', 'tabs_users'] },
  },
  {
    id: 2,
    title: 'tabs_admins',
    value: 'admin',
    breadcrumbValue: { icon: usersIcon, items: ['tabs_users', 'tabs_admins'] },
  },
  {
    id: 3,
    title: 'tabs_pending',
    value: 'pending',
    breadcrumbValue: { icon: usersIcon, items: ['tabs_users', 'tabs_pending'] },
  },
  {
    id: 4,
    title: 'tabs_archived',
    value: 'archived',
    breadcrumbValue: { icon: usersIcon, items: ['tabs_users', 'tabs_archived'] },
  },
];
export const ChangeUserAccessLevel = async (role, user_id, users, setUsers) => {
  let result = await CallAPI('UPDATE_USER_ROLE', {
    userId: user_id,
    userRole: role,
  });
  if (result.status) {
    let userList = users;
    userList.map((singleUser) => {
      if (user_id === singleUser._id) {
        singleUser.userRole = role;
      }
    });
    setUsers(userList);
  }
};

export const AdminTableFields = [
  {
    name: '',
    isSelected: true,
    displayName: '',
    populateFunc: 'userInitials',
    isFilterAble: false,
    type: 'text',
  },
  {
    name: 'firstName',
    displayName: 'table_header_first_name',
    mandatory: true,
    isSelected: true,
    associatedKey: 'user.firstName',
    isFilterAble: true,
    type: 'text',
  },
  {
    name: 'lastName',
    displayName: 'table_header_last_name',
    mandatory: true,
    isSelected: true,
    associatedKey: 'user.lastName',
    isFilterAble: true,
    type: 'text',
  },
  {
    name: 'email',
    displayName: 'table_header_email',
    isSelected: true,
    associatedKey: 'email',
    isFilterAble: true,
    type: 'text',
  },
  {
    name: 'permission',
    displayName: 'table_header_permissions',
    isSelected: true,
    populateFunc: 'populatePermissions',
    isFilterAble: false,
    type: 'text',
  },
  {
    name: 'type',
    displayName: 'table_header_access',
    isSelected: true,
    isFilterAble: false,
    type: 'text',
    populateFunc: 'populateAccess',
    callbackFunc: 'ChangeUserAccess',
  },
  {
    name: 'group',
    displayName: 'table_header_groups',
    isSelected: true,
    isFilterAble: false,
    type: 'text',
    associatedKey: 'userGroups',
    populateFunc: 'populateGroup',
  },
  {
    name: 'tags',
    displayName: 'table_header_tags',
    isSelected: true,
    associatedKey: 'tags',
    populateFunc: 'populateTags',
    isFilterAble: false,
    type: 'text',
  },
  {
    name: 'countryCode',
    displayName: 'table_header_country_code',
    isSelected: true,
    associatedKey: 'user.countryCode',
    isFilterAble: false,
    type: 'text',
  },
  {
    name: 'phone',
    displayName: 'table_header_mobile_phone',
    isSelected: true,
    associatedKey: 'user.mobileNumber',
    isFilterAble: false,
    type: 'text',
  },
  {
    name: 'department',
    displayName: 'table_header_department',
    isSelected: true,
    associatedKey: 'department',
    isFilterAble: false,
    type: 'text',
  },
  {
    name: 'title',
    displayName: 'table_header_title',
    isSelected: true,
    associatedKey: 'title',
    isFilterAble: false,
    type: 'text',
  },
  {
    name: 'lastLogin',
    displayName: 'table_header_last_login',
    isSelected: true,
    associatedKey: 'lastLoginDate',
    populateFunc: 'populateDate',
    isFilterAble: false,
    type: 'date',
  },
  {
    name: 'dateAdded',
    displayName: 'table_header_date_added',
    isSelected: true,
    associatedKey: 'userRoleAccessDate',
    populateFunc: 'populateDate',
    isFilterAble: false,
    type: 'date',
  },
  {
    name: 'startDate',
    displayName: 'table_header_start_date',
    isSelected: true,
    associatedKey: 'startDate',
    populateFunc: 'populateDate',
    isFilterAble: false,
    type: 'date',
  },
];
export const UserTableFields = [
  {
    name: '',
    isSelected: true,
    displayName: '',
    type: 'initial',
    isRequired: false,
    populateFunc: 'userInitials',
  },
  {
    name: 'firstName',
    displayName: 'table_header_first_name',
    type: 'string',
    isRequired: true,
    isSelected: true,
    isFilterAble: true,
    mandatory: true,
    associatedKey: 'firstName',
  },
  {
    name: 'lastName',
    displayName: 'table_header_last_name',
    type: 'string',
    mandatory: true,
    isRequired: true,
    isSelected: true,
    isFilterAble: true,
    associatedKey: 'lastName',
  },
  {
    name: 'email',
    displayName: 'table_header_email',
    type: 'string',
    isRequired: true,
    isSelected: true,
    isFilterAble: true,
    associatedKey: 'email',
  },
  {
    name: 'countryCode',
    displayName: 'table_header_country_code',
    isSelected: true,
    associatedKey: 'countryCode',
    isFilterAble: true,
    type: 'text',
  },
  {
    name: 'mobileNumber',
    displayName: 'table_header_mobile_phone',
    type: 'number',
    isRequired: true,
    isSelected: true,
    isFilterAble: true,
    associatedKey: 'user.mobileNumber',
  },
  {
    name: 'group',
    displayName: 'table_header_groups',
    isSelected: true,
    type: 'text',
    associatedKey: 'userGroups',
    populateFunc: 'populateGroup',
  },
  {
    name: 'tags',
    displayName: 'table_header_tags',
    isSelected: true,
    type: 'text',
    associatedKey: 'tags',
    populateFunc: 'populateTags',
  },
  {
    name: 'department',
    displayName: 'table_header_department',
    type: 'string',
    isRequired: false,
    isSelected: false,
    isFilterAble: true,
    associatedKey: 'department',
  },
  {
    name: 'eType',
    displayName: 'table_header_employment_type',
    type: 'string',
    isRequired: false,
    isSelected: false,
    isFilterAble: true,
    associatedKey: 'employmentType',
  },
  {
    name: 'idNumber',
    displayName: 'table_header_id_number',
    type: 'string',
    isRequired: false,
    isSelected: false,
    associatedKey: 'user.idNumber',
  },
  {
    name: 'nationality',
    displayName: 'table_header_nationality',
    type: 'string',
    isRequired: false,
    isSelected: true,
    isFilterAble: true,
    associatedKey: 'nationality',
  },
  {
    name: 'birthDate',
    displayName: 'table_header_birth_date',
    type: 'date',
    isRequired: false,
    isSelected: true,
    associatedKey: 'birthDate',
  },
  {
    name: 'idExpire',
    displayName: 'table_header_id_expire_date',
    type: 'date',
    isRequired: false,
    isSelected: true,
    associatedKey: 'idExpireDate',
  },
];
export const PendingUserTableFields = [
  {
    name: '',
    isSelected: true,
    displayName: '',
    type: 'initial',
    isRequired: false,
    populateFunc: 'userInitials',
  },
  {
    name: 'fname',
    displayName: 'table_header_first_name',
    type: 'string',
    mandatory: true,
    isRequired: true,
    isSelected: true,
    associatedKey: 'firstName',
  },
  {
    name: 'lname',
    displayName: 'table_header_last_name',
    type: 'string',
    isRequired: true,
    mandatory: true,
    isSelected: true,
    associatedKey: 'lastName',
  },
  {
    name: 'email',
    displayName: 'table_header_email',
    type: 'string',
    isRequired: true,
    isSelected: true,
    associatedKey: 'user.email',
  },
  {
    name: 'countryCode',
    displayName: 'table_header_country_code',
    isSelected: true,
    associatedKey: 'user.countryCode',
    isFilterAble: false,
    type: 'text',
  },
  {
    name: 'mPhone',
    displayName: 'table_header_mobile_phone',
    type: 'number',
    isRequired: true,
    isSelected: true,
    associatedKey: 'user.mobileNumber',
  },
  {
    name: 'status',
    displayName: 'table_header_status',
    type: 'text',
    isRequired: false,
    isSelected: true,
    associatedKey: 'userStatus',
    translateVal: true,
    populateFunc: 'populateStatus',
  },
  {
    name: 'department',
    displayName: 'table_header_department',
    type: 'string',
    isRequired: false,
    isSelected: false,
    associatedKey: 'user.department',
  },
  {
    name: 'eType',
    displayName: 'table_header_employment_type',
    type: 'string',
    isRequired: false,
    isSelected: false,
    associatedKey: 'employmentType',
  },
  {
    name: 'idNumber',
    displayName: 'table_header_id_number',
    type: 'string',
    isRequired: false,
    isSelected: false,
    associatedKey: 'user.idNumber',
  },
  {
    name: 'nationality',
    displayName: 'table_header_nationality',
    type: 'string',
    isRequired: false,
    isSelected: false,
    associatedKey: 'nationality',
  },
  {
    name: 'birthDate',
    displayName: 'table_header_birth_date',
    type: 'date',
    isRequired: false,
    isSelected: false,
    associatedKey: 'birthDate',
  },
  {
    name: 'idExpire',
    displayName: 'table_header_id_expire_date',
    type: 'date',
    isRequired: false,
    isSelected: false,
    associatedKey: 'idExpireDate',
  },
];
export const PendingUserSortOrder = [
  {
    value: 'all',
    displayValue: 'select_show_all',
    isNested: false,
    variant: 'green-1',
  },
  {
    value: 'pending',
    displayValue: 'select_pending',
    isNested: false,
    variant: 'orange-2',
  },
  {
    value: 'admin_invite',
    displayValue: 'select_invited',
    isNested: false,
    variant: 'blue-1',
  },
];

export const UserUnassignedFilterList = [
  {
    value: 'all',
    displayValue: 'select_show_all',
    isNested: false,
    variant: 'green-1',
  },
  {
    value: 'unassigned',
    displayValue: 'select_unassigned',
    isNested: false,
    variant: 'blue-1',
  },
];

export const permissionTypeList = [
  {
    _id: 'create',
    displayName: 'CreateEditPermission',
  },
  {
    _id: 'delete',
    displayName: 'DeletePermission',
  },
  {
    _id: 'read',
    displayName: 'ViewPermission',
  },
];

export const userAccessTabsList = [
  {
    id: 'sub1',
    title: 'Forms',
    isSelected: true,
    name: 'forms',
    icon: formGreyIcon,
    selectedIcon: formIconWhite,
  },
  // {
  //   id: 'sub2',
  //   title: 'Issues',
  //   isSelected: false,
  //   name: 'issues',
  //   icon: issueIcon,
  //   selectedIcon: issueIconWhite,
  // },
];
export const userAccessedFormHeader = [
  {
    name: 'no',
    displayName: '#',
    type: 'string',
    isRequired: true,
    isSelected: true,
    associatedKey: 'No',
  },
  {
    name: 'title',
    displayName: 'Form Name',
    type: 'string',
    isRequired: true,
    isSelected: true,
    populateFunc: 'populateAccessedFormTitle',
  },
  {
    name: 'publishedDate',
    displayName: 'Published',
    type: 'string',
    isRequired: true,
    isSelected: true,
    associatedKey: 'currentVersion.publishedDate',
    populateFunc: 'populateDate',
  },
  {
    name: 'lastEntery',
    displayName: 'LastEntry',
    type: 'string',
    isRequired: true,
    isSelected: true,
    associatedKey: 'lastEntryDate',
    populateFunc: 'populateDate',
  },
  {
    name: 'entries',
    displayName: 'Entries',
    type: 'string',
    isRequired: true,
    isSelected: true,
    associatedKey: 'numberOfEntries',
  },
];
export const addUserFieldList = [
  {
    name: 'firstName',
    displayName: 'table_header_first_name',
    type: 'text',
    mandatory: true,
    isSelected: true,
  },
  {
    name: 'lastName',
    displayName: 'table_header_last_name',
    type: 'text',
    mandatory: true,
    isSelected: true,
  },
  {
    name: 'mobileNumber',
    displayName: 'table_header_phone_number',
    type: 'mobile',
    mandatory: true,
    isSelected: true,
  },
  {
    name: 'email',
    displayName: 'table_header_email',
    type: 'text',
    mandatory: false,
    isSelected: false,
  },
  {
    name: 'department',
    displayName: 'table_header_department',
    type: 'text',
    mandatory: false,
    isSelected: false,
  },
  {
    name: 'branch',
    displayName: 'table_header_branch',
    type: 'text',
    mandatory: false,
    isSelected: false,
  },
  {
    name: 'title',
    displayName: 'table_header_title',
    type: 'text',
    mandatory: false,
    isSelected: false,
  },
  {
    name: 'employmentType',
    displayName: 'table_header_employment_type',
    type: 'text',
    mandatory: false,
    isSelected: false,
  },
  {
    name: 'personalIdNumber',
    displayName: 'table_header_personal_id_number',
    type: 'number',
    mandatory: false,
    isSelected: false,
  },
  {
    name: 'personalIdExpiry',
    displayName: 'table_header_personal_id_expiry',
    type: 'date',
    mandatory: false,
    isSelected: false,
  },
  {
    name: 'nationality',
    displayName: 'table_header_nationality',
    type: 'text',
    mandatory: false,
    isSelected: false,
  },
  {
    name: 'birthDate',
    displayName: 'table_header_date_of_birth',
    type: 'date',
    mandatory: false,
    isSelected: false,
  },
  {
    name: 'assign_groups',
    displayName: 'table_header_assign_groups',
    type: 'group_selection',
    mandatory: false,
    isSelected: false,
  },
];
