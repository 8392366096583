import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import editBlackIcon from 'assets/images/icons/edit-square-grey.svg';
import trashGreyIcon from 'assets/images/icons/trash-grey.svg';
import ConfirmationModal from 'components/common-components/ConfirmationModal';
import Error from 'components/Error';
import FormButton from 'components/form-components/FormButton';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import TextField from 'components/form-components/TextField';
import { addDataSourceColumnSchema } from '../utils';
import { CallAPI } from 'actions/General';

const EditDataSourceColumn = ({ show, toggle, columnData, fieldsList, refreshList }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const [values, setValues] = useState({ columnName: columnData.columnName });
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [APIError, setAPIError] = useState('');

  const handleColumnNameChange = (field, value) => {
    if (!value.includes('.')) {
      setTouched({ ...touched, [field]: true });
      setErrors({ ...errors, [field]: undefined });
      setValues({ ...values, [field]: value });
    }
  };

  const verifyAPIData = () => {
    const allColumns = fieldsList.map((singleField) => singleField.name.toLowerCase());

    if (allColumns.indexOf(values.columnName.trim().toLowerCase()) > -1) {
      setErrors({ columnName: 'Column Name already exist' });
      return false;
    }
    return true;
  };

  const renameDataSourceColumn = async () => {
    try {
      setTouched({});
      await addDataSourceColumnSchema.validate(values, { abortEarly: false });
      const verifyData = verifyAPIData();
      if (verifyData) {
        const result = await CallAPI(
          'RENAME_COLUMN_IN_DATASOURCE',
          {
            dataSourceId: location.state.dataSource?._id,
            columnToRename: { id: columnData.columnId, title: columnData.columnName },
            columnNewName: values.columnName.trim(),
          },
          null,
          setLoader,
          setAPIError,
          null,
        );
        if (result.status) {
          refreshList();
          toggle();
        }
      }
    } catch (error) {
      const schemaErrors = {};
      error.inner?.forEach((err) => {
        schemaErrors[err.path] = err.message;
      });
      setErrors(schemaErrors);
    }
  };

  const deleteDataSourceColumn = async () => {
    const result = await CallAPI(
      'DELETE_COLUMN_IN_DATASOURCE',
      {
        dataSourceId: location.state.dataSource?._id,
        column: { id: columnData.columnId, title: columnData.columnName },
      },
      null,
      null,
      null,
      null,
    );
    if (result.status) {
      refreshList();
    }
  };

  useEffect(() => {
    setTouched({});
    setErrors({});
    setValues({ ...values, columnName: columnData.columnName });
  }, [show]);

  const popover = (
    <Popover className='min-width-176' id='popover-basic'>
      <Popover.Header className='theme-background-white-2 theme-text-black-1' as='h3'>
        {t('button_edit_column')}
      </Popover.Header>
      <Popover.Body>
        {APIError && <Error msg={APIError} />}
        <TextField
          name='columnName'
          displayName=''
          placeholder={t('field_data_source_column_name')}
          handleChange={handleColumnNameChange}
          value={values.columnName}
          error={errors.columnName}
          touched={touched.columnName}
          classes='mb-3'
          shrink={true}
        />
        <div className='d-flex gap-2 justify-content-between'>
          <FormButton text='button_cancel' variant='white-1' onClick={() => toggle()} />
          <FormLoadingButton
            loading={loader}
            text='button_save'
            variant='green-1'
            onClick={() => renameDataSourceColumn()}
          />
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <Fragment>
      <OverlayTrigger
        trigger='click'
        onToggle={() => toggle()}
        show={show}
        rootClose
        placement='bottom'
        overlay={popover}
      >
        <img src={editBlackIcon} className='cursor-pointer' />
      </OverlayTrigger>
      <img
        src={trashGreyIcon}
        onClick={() => setDeleteConfirmationModal(true)}
        className='cursor-pointer'
      />
      <ConfirmationModal
        show={deleteConfirmationModal}
        title={t('alert_common_title')}
        message={t('alert_data_source_column_deletion_message')}
        actionList={[
          {
            color: 'black-1',
            text: t('button_cancel'),
            onClick: () => setDeleteConfirmationModal(false),
          },
          {
            color: 'red-1',
            text: t('button_delete'),
            onClick: () => {
              deleteDataSourceColumn();
              setDeleteConfirmationModal(false);
            },
          },
        ]}
      />
    </Fragment>
  );
};

EditDataSourceColumn.propTypes = {
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  columnData: PropTypes.object.isRequired,
  refreshList: PropTypes.func.isRequired,
  fieldsList: PropTypes.array.isRequired,
};

export default EditDataSourceColumn;
