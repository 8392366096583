import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';
import toast from 'react-hot-toast';
import AlertModal from 'components/common-components/AlertModal';
import Error from 'components/Error';
import FormButton from 'components/form-components/FormButton';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import Loader from 'components/common-components/Loader';
import OTPField from 'components/form-components/OTPField';
import TextField from 'components/form-components/TextField';
import * as firebase from 'config/fbConfig';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { CallAPI } from 'actions/General';
import { logout, updateSavedChanges } from 'config/config';

const CompanyDeleteModal = ({ showModal, setShowModal }) => {
  const { t } = useTranslation();
  const [values, setValues] = useState({ deleteText: '', otp: '' });
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [phoneNumber, setPhoneNumber] = useState('');
  const [confirmationResultState, setConfirmationResultState] = useState(null);
  const [otpVerified, setOtpVerified] = useState(false);
  const [apiError, setApiError] = useState('');
  const [loading, setLoading] = useState(false);
  const [resendOTP, setResendOTP] = useState(false);
  const [resendOTPLoading, setResendOTPLoading] = useState(false);

  const handleChange = (field, value) => {
    setApiError('');
    setTouched({ ...touched, [field]: true });
    setErrors({ ...errors, [field]: undefined });
    setValues({ ...values, [field]: value });
  };

  const confirmTypeDelete = async () => {
    setTouched({});
    if (values.deleteText !== 'Delete') {
      setErrors({ deleteText: 'Incorrect typed' });
    } else {
      setLoading(true);
      const verifier = window.recaptchaVerifier;
      await signInWithPhoneNumber(firebase.auth, phoneNumber, verifier)
        .then((confirmationResult) => {
          setConfirmationResultState(confirmationResult);
        })
        .catch(async (error) => {
          if (error.code === 'auth/invalid-phone-number') setApiError('invalid phone number');
          else if (error.code === 'auth/error-code:-39')
            setApiError('Too many tries, Please try again later');
          else setApiError(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onResendOTP = async () => {
    setResendOTPLoading(true);
    const verifier = window.recaptchaVerifier;
    await signInWithPhoneNumber(firebase.auth, phoneNumber, verifier)
      .then((confirmationResult) => {
        setConfirmationResultState(confirmationResult);
        setResendOTP(true);
        toast(t('New OTP sent'), {
          style: { borderRadius: '8px', background: '#000', color: '#fff' },
        });
      })
      .catch(async () => {});
    setResendOTPLoading(false);
  };

  const onSubmitOTP = async () => {
    setTouched({});
    setLoading(true);
    if (values.otp.length < 6) {
      setErrors({ otp: 'At least 6 digits' });
      setLoading(false);
    } else {
      await confirmationResultState
        .confirm(values.otp)
        .then(async () => {
          await deleteCompany();
        })
        .catch(async (error) => {
          if (error.code === 'auth/invalid-verification-code') setApiError('code is not valid');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const deleteCompany = async () => {
    const deleteResponse = await CallAPI('DELETE_COMPANY', null, null, setLoading, setApiError);

    if (deleteResponse.status) {
      setOtpVerified(true);
      updateSavedChanges(true, 'COMPANY_DELETED');
      setConfirmationResultState(null);
    }
  };

  useEffect(() => {
    // setPhoneNumber('+966123456781');
    if (firebase.auth.currentUser?.email) {
      setPhoneNumber(
        // eslint-disable-next-line no-undef
        firebase.auth.currentUser.email.replace(process.env.REACT_APP_EMAIL_SUFFIX, ''),
      );
    }
  }, []);

  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(firebase.auth, 'register-button', {
      size: 'invisible',
    });
  }, []);

  return (
    <AlertModal show={showModal} closeModal={() => setShowModal(false)} actionButtons={[]}>
      <div className='w-75 d-flex flex-column gap-3 align-items-center'>
        {apiError ? <Error msg={apiError} /> : <></>}

        {otpVerified ? (
          <Fragment>
            <label className='theme-font-inter-medium theme-text-black-1 theme-size-1_5'>
              {t('text_company_suspended')}
            </label>
            <label className='text-justify'>{t('text_company_suspended_explanation')}</label>
            <FormButton
              text='button_sign_out'
              variant='red-1'
              classes='w-100'
              largeSize
              onClick={logout}
            />
          </Fragment>
        ) : (
          <Fragment>
            {!confirmationResultState ? (
              <Fragment>
                <label className='theme-font-inter-medium theme-text-black-1 theme-size-1_5'>
                  {t('alert_delete_company_confirm_title')}
                </label>

                <TextField
                  name='deleteText'
                  displayName='field_type_delete'
                  placeholder='field_type_delete'
                  value={values.deleteText}
                  error={errors.deleteText}
                  touched={touched.deleteText}
                  handleChange={handleChange}
                />
                <div className='w-100 d-flex justify-content-around'>
                  <label
                    className='theme-font-inter-medium theme-text-black-1 theme-size-1_2 cursor-pointer'
                    onClick={() => setShowModal(false)}
                  >
                    {t('button_cancel')}
                  </label>
                  <label className='d-flex gap-2'>
                    <span
                      className='theme-font-inter-medium theme-text-green-1 theme-size-1_2 cursor-pointer'
                      onClick={() => confirmTypeDelete()}
                    >
                      {t('button_confirm')}
                    </span>
                    {loading && <Loader color='green-1' />}
                  </label>
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <label className='theme-font-inter-medium theme-text-red-1 theme-size-1_5'>
                  {t('alert_delete_company_confirm_title')}
                </label>
                <label className='theme-font-inter-medium theme-text-black-1 theme-size-1_5'>
                  {t('text_otp_verification')}
                </label>
                <label className='theme-text-black-1 w-75 text-center'>
                  {t('text_sent_otp_on_this_number')}: {phoneNumber}
                </label>
                <OTPField
                  name='otp'
                  handleChange={handleChange}
                  error={errors.otp}
                  touched={touched.otp}
                />
                <FormLoadingButton
                  text='button_verify'
                  variant='green-1'
                  loading={loading}
                  classes='w-100'
                  largeSize
                  onClick={onSubmitOTP}
                />
                {!resendOTP && (
                  <Fragment>
                    <div>
                      <span className='helper-text'>{t('text_did_not_receive_otp')} </span>
                      <span
                        {...(!resendOTPLoading && { onClick: () => onResendOTP() })}
                        className='theme-text-green-1 cursor-pointer'
                      >
                        {t('text_resend_otp')}
                      </span>
                      {resendOTPLoading && (
                        <span>
                          <Spinner size='sm' className='theme-text-green-1 ms-2' />
                        </span>
                      )}
                    </div>
                  </Fragment>
                )}
              </Fragment>
            )}
          </Fragment>
        )}

        <div id='register-button'></div>
      </div>
    </AlertModal>
  );
};

CompanyDeleteModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

export default CompanyDeleteModal;
