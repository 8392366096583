import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const OTPField = ({ name, handleChange, error, touched }) => {
  const { t } = useTranslation();
  const OTPLength = 6;
  const [code, setCode] = useState(Array.from(Array(OTPLength).keys()).fill(''));
  const inputRefs = useRef([]);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    inputRefs.current[0].focus();
  }, []);

  const showFocus = (e, index) => {
    e.preventDefault();
    if (activeIndex !== index) inputRefs.current[activeIndex].focus();
  };

  const changeHandler = (e, index) => {
    e.preventDefault();
    const currentValue = code[index];
    if (currentValue === '') {
      const newValue = [...code];
      newValue[index] = e.target.value;
      setCode(newValue);
    }
    if (e.target.value !== '' && index < OTPLength - 1) {
      inputRefs.current[activeIndex + 1].focus();
      setActiveIndex(activeIndex + 1);
    } else if (e.target.value === '' && currentValue === '' && index > 0) {
      inputRefs.current[activeIndex - 1].focus();
      setActiveIndex(activeIndex - 1);
    }
  };

  const removeValue = (e, index) => {
    const currentValue = code[index];

    if (currentValue === '') {
      if (index > 0) {
        inputRefs.current[index - 1].focus();
        setActiveIndex(index - 1);
      }
    } else {
      const newValue = [...code];
      newValue[index] = '';
      setCode(newValue);
    }
  };

  const keyDownHandler = (e, index) => {
    const pressedKey = e.key;
    switch (pressedKey) {
      case 'Backspace':
      case 'Delete': {
        removeValue(e, index);
        break;
      }
    }
  };

  useEffect(() => {
    handleChange(name, code.join(''));
  }, [code]);

  return (
    <div className='custom-input-box'>
      <div className='custom-otp-field'>
        {Array.from(Array(OTPLength).keys())
          .fill('')
          .map((_, index) => (
            <input
              type='number'
              key={index}
              ref={(el) => (inputRefs.current[index] = el)}
              {...(activeIndex !== index && { style: { cursor: 'not-allowed' } })}
              onClick={(e) => showFocus(e, index)}
              onKeyDown={(e) => keyDownHandler(e, index)}
              onChange={(e) => changeHandler(e, index)}
              onPaste={(e) => e.preventDefault()}
              inputMode='numeric'
              value={code[index]}
            />
          ))}
      </div>
      {!touched && error && <p className='form-field-error'>{t(error)}</p>}
    </div>
  );
};

OTPField.propTypes = {
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  touched: PropTypes.bool,
};

export default OTPField;
