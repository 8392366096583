import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ToggleBox from 'components/toggler-components/ToggleBox';
import ToggleBoxHeader from 'components/toggler-components/ToggleBoxHeader';
import ToggleBoxBody from 'components/toggler-components/ToggleBoxBody';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import tablePropertiesIcon from 'assets/images/icons/table-properties.svg';
import { fieldTypeAllowedProperties } from './utils';

import SingleFieldProperties from './SingleFieldProperties';
import { updateSavedChanges } from 'config/config';

function FormProperties({
  selectedField,
  formProperties,
  setFormProperties,
  toggleList,
  isNestedOption,
  dataSourcesList,
}) {
  const { t } = useTranslation();

  const [elementPropertiesType, setElementPropertiesType] = useState(toggleList);
  const [selectedElementIndex, setSelectedElementIndex] = useState(-1);

  useEffect(() => {
    let list = toggleList;
    if (selectedField && selectedField.elementType) {
      list = toggleList.filter((singleTab) => {
        if (singleTab.excludedFor.indexOf(selectedField.elementType) === -1) return true;
        else return false;
      });
      setSelectedElementIndex(selectedField.position);
    } else {
      setSelectedElementIndex(-1);
    }
    setElementPropertiesType(list);
  }, [selectedField]);

  const rightToggleBoxOpen = (index) => {
    const elementsList = cloneDeep(elementPropertiesType);
    elementsList[index].isOpen = !elementsList[index].isOpen;
    setElementPropertiesType(elementsList);
  };
  const onChangeProperties = (key, value, type) => {
    updateSavedChanges(true, 'FORM_DESIGN');
    let formElementProperties = cloneDeep(formProperties);

    let elementAddedIn =
      selectedField.type === 'user-field' ? 'page' + selectedField.currentPage : 'supervisorFields';

    let elementProperties =
      formElementProperties[elementAddedIn].elements[selectedField.position].properties;
    if (selectedField.elementType === 'dateTime' && selectedField.type === 'user-field') {
      if (elementProperties.validationProperties['minRange']) {
        delete elementProperties.validationProperties['minRange'];
        delete elementProperties.validationProperties['maxRange'];
      }
    } else if (key === 'required' && value && selectedField.type === 'user-field') {
      elementProperties[type]['readOnly'] = false;
      elementProperties[type]['hidden'] = false;
    } else if (key === 'dataSource' && elementProperties[type].dataSource !== value) {
      elementProperties[type].displayColumn = '';
      elementProperties[type].valueColumn = '';
      elementProperties[type].bindingList = [];
    } else if (key === 'bindingList') {
      let selectedElement = formElementProperties[elementAddedIn].elements[selectedField.position];
      let formBindingObj = {
        ...formElementProperties.otherDetails.bindingElementsObj,
        [value.fieldId]: {
          fieldName: elementProperties[type].fieldTitle,
          fieldId: selectedElement.elementGlobalId
            ? selectedElement.elementGlobalId
            : selectedElement.uniqueIdToReplace,
        },
      };
      value = [...elementProperties[type].bindingList, value];
      formElementProperties.otherDetails.bindingElementsObj = formBindingObj;
    } else if (key === 'fieldTitle' && selectedField.type === 'user-field') {
      let selectedElement = formElementProperties[elementAddedIn].elements[selectedField.position];
      let updatedElementId = selectedElement.elementGlobalId
        ? selectedElement.elementGlobalId
        : selectedElement.uniqueIdToReplace;
      if (formElementProperties.otherDetails.allFormElementsObj[updatedElementId])
        formElementProperties.otherDetails.allFormElementsObj = {
          ...formElementProperties.otherDetails.allFormElementsObj,
          [updatedElementId]: {
            fieldName: value ? value : 'UnTitled',
            fieldId: updatedElementId,
          },
        };
    } else if (key === 'removeBindingItem') {
      key = 'bindingList';
      const removedIndex = value.index;
      value = elementProperties[type][key];
      value.splice(removedIndex, 1);
      delete formElementProperties.otherDetails.bindingElementsObj[value.removedFieldId];
    } else if (key === 'requiredPictures' && value) {
      elementProperties[type].minPictures =
        parseInt(elementProperties[type].minPictures) > 1 ? elementProperties[type].minPictures : 1;
    } else if (
      [
        'minRange',
        'maxRange',
        'videoMinutes',
        'videoSeconds',
        'minPictures',
        'maxPictures',
      ].includes(key)
    ) {
      value = Number(value);
    }
    elementProperties[type][key] = value;
    formElementProperties[elementAddedIn].elements[selectedField.position].properties =
      elementProperties;
    setFormProperties(formElementProperties);
  };
  return (
    <>
      {(selectedField && selectedElementIndex === selectedField.position) ||
      selectedElementIndex === -1 ? (
        <>
          {!isNestedOption && (
            <div className='section-header-area'>
              <div className='d-flex gap-2 align-items-center'>
                <img src={tablePropertiesIcon} />
                <p className='theme-text-black-1 theme-size-1_2 mb-0'>
                  {t('form_edit_properties')}
                </p>
              </div>
              <p className='text-center mb-0 theme-size-0_9'>
                {t('form_choose_the_options_as_you_like')}
              </p>
            </div>
          )}
          {elementPropertiesType.map((toggleBox, index) => {
            let propertiesList = selectedField
              ? selectedField.type === 'user-field'
                ? formProperties['page' + selectedField.currentPage].elements[
                    selectedField.position
                  ].properties
                : formProperties.supervisorFields.elements[selectedField.position].properties
              : {};
            return (
              <ToggleBox key={index}>
                <ToggleBoxHeader
                  show={toggleBox.isOpen}
                  toggleBoxOpen={() => rightToggleBoxOpen(index)}
                  title={toggleBox.title}
                />
                <ToggleBoxBody
                  show={toggleBox.isOpen}
                  classes={`${
                    !isNestedOption ? 'p-3 pt-0 d-flex flex-column gap-2' : 'd-flex flex-wrap gap-3'
                  }`}
                >
                  <Fragment>
                    {selectedField ? (
                      toggleBox.nestedOption ? (
                        <FormProperties
                          selectedField={selectedField}
                          formProperties={formProperties}
                          setFormProperties={setFormProperties}
                          toggleList={toggleBox.nestedOption}
                          isNestedOption={true}
                          dataSourcesList={dataSourcesList}
                        />
                      ) : (
                        fieldTypeAllowedProperties[toggleBox.defaultPropsKey].map(
                          (singleProperty, index) => {
                            if (
                              (singleProperty.isAllowedForAll &&
                                singleProperty.restrictedFieldTypes.indexOf(
                                  selectedField.elementType,
                                ) === -1) ||
                              singleProperty.allowedFieldTypes.indexOf(selectedField.elementType) >
                                -1
                            ) {
                              let fieldSectionProperties =
                                propertiesList[toggleBox.defaultPropsKey + 'Properties'];
                              return (
                                fieldSectionProperties && (
                                  <SingleFieldProperties
                                    key={index}
                                    selectedField={selectedField}
                                    onChangeProperties={onChangeProperties}
                                    singleProperty={singleProperty}
                                    fieldSectionProperties={fieldSectionProperties}
                                    toggleBox={toggleBox}
                                    propertiesList={propertiesList}
                                    otherDetails={formProperties.otherDetails}
                                    dataSourcesList={
                                      selectedField.elementType === 'dataSource'
                                        ? dataSourcesList
                                        : []
                                    }
                                  />
                                )
                              );
                            }
                          },
                        )
                      )
                    ) : (
                      ''
                    )}
                  </Fragment>
                </ToggleBoxBody>
              </ToggleBox>
            );
          })}
        </>
      ) : (
        <></>
      )}
    </>
  );
}
FormProperties.propTypes = {
  selectedField: PropTypes.object,
  formProperties: PropTypes.object.isRequired,
  setFormProperties: PropTypes.func.isRequired,
  toggleList: PropTypes.array.isRequired,
  dataSourcesList: PropTypes.array.isRequired,
  isNestedOption: PropTypes.bool.isRequired,
};
export default FormProperties;
