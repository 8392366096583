import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import AlertModal from 'components/common-components/AlertModal';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import FormButton from 'components/form-components/FormButton';
import dotsIcon from 'assets/images/icons/table-icons.svg';
import dropdownIcon from 'assets/images/icons/dropdown-arrow-white.svg';
import { useNavigate } from 'react-router-dom';
import { CallAPI } from 'actions/General';
import { isPermissionAvailable } from 'config/permissionUtils';
import { isRTL, permissionKeys } from 'config/config';
import { checkHasActionPermission } from './utils';
import SingleRadioBoxField from 'components/form-components/SingleRadioBoxField';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import { useSelector } from 'react-redux';

function FormActions({ ids, isActionButton, actionsCallBack, type, actionData }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [displayedMenu, setDisplayedMenu] = useState([]);
  const [allFolders, setAllFolders] = useState([]);
  const [companiesList, setCompaniesList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState('');
  const [APICalled, setAPICalled] = useState(false);
  const applyFormActions = async (action) => {
    await CallAPI(
      'APPLY_FORM_ACTIONS',
      {
        action: action,
        forms: ids,
      },
      null,
      null,
      null,
      null,
    );
    setShowDeleteConfirm(false);
    actionsCallBack();
  };
  const duplicateForm = async () => {
    await CallAPI(
      'APPLY_FORM_DUPLICATE_ACTION',
      {
        form: ids[0],
        isTestForm: false,
      },
      null,
      null,
      null,
      null,
    );
    setShowDeleteConfirm(false);
    actionsCallBack();
  };

  const getAllFolders = async () => {
    if (type.indexOf('row') === -1 && !APICalled) {
      setAPICalled(true);
      let result = await CallAPI('GET_ALL_FOLDERS', {}, null, null, null, null);
      if (result.status) {
        setAllFolders(result.data);
      }
    }
  };

  const moveToFolder = async (folder) => {
    await CallAPI(
      'APPLY_FORM_ACTIONS',
      {
        action: 'move_to_folder',
        forms: ids,
        moveToFolder: { id: folder._id, title: folder.name },
      },
      null,
      null,
      null,
      null,
    );
    setShowDeleteConfirm(false);
    actionsCallBack();
  };
  const removeFromFolder = async () => {
    let folderDetail = {};
    allFolders.map((singleFolder) => {
      if (actionData.filterFolderIds[0] === singleFolder._id) {
        folderDetail = { id: singleFolder._id, title: singleFolder.name };
      }
    });
    await CallAPI(
      'APPLY_FORM_ACTIONS',
      {
        action: 'remove_from_folder',
        forms: ids,
        moveToFolder: folderDetail,
      },
      null,
      null,
      null,
      null,
    );
    setShowDeleteConfirm(false);
    actionsCallBack();
  };

  const getCompaniesForFormDuplication = async () => {
    setCompaniesList([]);
    setSelectedCompany(null);
    setShowDuplicateModal(true);
    const companiesResponse = await CallAPI(
      'GET_COMPANIES_FOR_FORM_DUPLICATION',
      null,
      null,
      null,
      null,
      null,
    );
    if (companiesResponse.status) {
      const list = companiesResponse.data.companies;
      setCompaniesList(list);
    }
  };

  const copyForm = async () => {
    setLoading(true);
    if (selectedCompany) {
      const payload = {
        form: ids[0],
        company: { id: selectedCompany.company._id, title: selectedCompany.company.companyName },
        companyUser: {
          id: selectedCompany._id,
          title: auth.profile.firstName + ' ' + auth.profile.lastName,
        },
        userRoleOtherCompany: selectedCompany.userRole,
        isTestForm: false,
      };

      const response = await CallAPI('DUPLICATE_FORM_TO_OTHER_COMPANY', payload, null, setLoading);
      if (response.status) {
        toast.success('Form duplicated successfully');
        setShowDuplicateModal(false);
      }
    }
  };

  const confirmDeletion = () => setShowDeleteConfirm(true);

  useEffect(() => {
    if (type !== 'submittedForms' && type !== 'row_dashboardForms' && type !== 'bookmarkedForms')
      getAllFolders();
    const formActionsList = [
      {
        name: 'action_edit_template',
        permission: checkHasActionPermission(actionData.designersList, actionData.creator),
        allowedFrom: ['row_forms', 'row_bookmarks'],
        action: () =>
          navigate('/forms/edit', {
            relative: 'path',
            state: { type: 'edit', formId: ids[0].id },
          }),
      },
      {
        allowedFrom: [
          'row_forms',
          'row_bookmarks',
          'forms',
          'bookmarks',
          'row_archived',
          'archived',
        ],
        permission: isPermissionAvailable(permissionKeys.field_work_forms_delete),
        name: 'action_delete_template',
        action: confirmDeletion,
      },
      {
        name: 'action_manage_access',
        permission: checkHasActionPermission(actionData.designersList, actionData.creator),
        allowedFrom: ['row_forms', 'row_bookmarks'],
        action: () =>
          navigate('/forms/edit', {
            relative: 'path',
            state: { type: 'edit', formId: ids[0].id, tab: 2 },
          }),
      },
      {
        name: 'action_view_inspections',
        permission: checkHasActionPermission(actionData.supervisorsList, actionData.creator),
        allowedFrom: ['row_forms', 'row_bookmarks', 'row_dashboardForms'],
        action: () => {
          navigate('/submissions', {
            relative: 'path',
            state: {
              type: 'edit',
              formId: ids[0].id,
              version: '',
              status: 'live',
              versionsList: actionData.versionsList,
            },
          });
        },
      },
      {
        name: '',
        permission: true,
        allowedFrom: ['row_forms', 'row_bookmarks'],
      },
      {
        name: 'action_bookmark',
        permission: isPermissionAvailable(permissionKeys.field_work_forms_create),
        allowedFrom: ['row_forms', 'forms', 'submittedForms'],
        action: () => applyFormActions('bookmark'),
      },
      {
        name: 'action_duplicate',
        permission: isPermissionAvailable(permissionKeys.field_work_forms_create),
        allowedFrom: ['row_forms'],
        action: () => duplicateForm(),
      },
      {
        name: 'action_remove_bookmark',
        permission: isPermissionAvailable(permissionKeys.field_work_forms_create),
        allowedFrom: ['row_bookmarks', 'bookmarks', 'bookmarkedForms'],
        action: () => applyFormActions('remove_bookmark'),
      },
      {
        name: 'action_duplicate_template_to_company',
        permission: isPermissionAvailable(permissionKeys.field_work_forms_create),
        allowedFrom: ['row_forms'],
        action: () => getCompaniesForFormDuplication(),
      },
      {
        name: '',
        permission: true,
        allowedFrom: ['row_forms', 'row_bookmarks'],
      },
      {
        name: 'action_archive_template',
        permission: isPermissionAvailable(permissionKeys.field_work_forms_create),
        allowedFrom: ['row_forms', 'row_bookmarks', 'forms', 'bookmarks'],
        action: () => applyFormActions('archive'),
      },
      {
        name: 'action_restore_template',
        permission: isPermissionAvailable(permissionKeys.field_work_forms_create),
        allowedFrom: ['row_archived', 'archived'],
        action: () => applyFormActions('archive_restore'),
      },
      {
        name: 'action_settings',
        permission: checkHasActionPermission(actionData.designersList, actionData.creator),
        allowedFrom: ['row_forms', 'row_bookmarks'],
        action: () =>
          navigate('/forms/edit', {
            relative: 'path',
            state: { type: 'edit', formId: ids[0].id, tab: 3 },
          }),
      },
      {
        name: 'action_add_to_folder',
        permission: isPermissionAvailable(permissionKeys.field_work_forms_create),
        allowedFrom: ['forms', 'bookmarks'],
        hasChild: allFolders,
        type: 'add_folder',
      },
      {
        name: 'action_remove_from_folder',
        permission:
          actionData &&
          actionData.filterFolderIds &&
          actionData.filterFolderIds.length === 1 &&
          isPermissionAvailable(permissionKeys.field_work_forms_create),
        allowedFrom: ['forms', 'bookmarks'],
        action: () => removeFromFolder(),
      },
    ];

    let formActionListUpdated = formActionsList.filter(
      (item) => item.allowedFrom.indexOf(type) > -1 && item.permission === true,
    );
    // if divider item has valie action item before and after, then show the divider
    let formActionListToUpdate = formActionListUpdated.filter((singleAction, index) => {
      if (singleAction.name !== '') return singleAction;
      else if (
        index > 0 &&
        index < formActionListUpdated.length &&
        formActionListUpdated[index - 1].name !== '' &&
        formActionListUpdated[index + 1].name !== ''
      ) {
        return singleAction;
      }
      return null;
    });
    setDisplayedMenu(formActionListToUpdate);
  }, [type, ids]);

  return (
    <div className='dropdown-actions'>
      {displayedMenu.length > 0 && (
        <DropdownButton
          disabled={ids.length > 0 ? false : true}
          as={ButtonGroup}
          drop='bottom'
          variant='secondary'
          align={isRTL() ? 'end' : 'start'}
          title={
            <>
              {isActionButton ? (
                <img src={dotsIcon} alt='Loading...' />
              ) : (
                <FormButton text='button_action' variant='green-1' icon={dropdownIcon} />
              )}
            </>
          }
        >
          {displayedMenu.map((button, index) =>
            button.name ? (
              <div key={index}>
                {button.hasChild ? (
                  <DropdownButton
                    onMouseEnter={() => setIsOpen(button.name)}
                    onMouseLeave={() => setIsOpen(false)}
                    show={isOpen === button.name ? true : false}
                    variant='secondary'
                    className={`items theme-size-1 theme-text-grey-1 px-3 py-2 cursor-pointer${
                      isRTL() ? ' backward-opened' : ''
                    }`}
                    title={<div className='theme-text-grey-1 child-dropdown'>{t(button.name)}</div>}
                  >
                    {button.hasChild.map((childBtn, childIndex) => (
                      <Dropdown.Item
                        key={childIndex}
                        onClick={() => {
                          button.type && button.type === 'add_folder'
                            ? moveToFolder(childBtn)
                            : null;
                        }}
                        className='items theme-size-1 theme-text-grey-1 px-3 py-2 cursor-pointer'
                      >
                        {t(childBtn.name)}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                ) : (
                  <Dropdown.Item
                    onClick={button.action}
                    key={index}
                    className='items theme-size-1 theme-text-grey-1 px-3 py-2 cursor-pointer'
                  >
                    {t(button.name)}
                  </Dropdown.Item>
                )}
              </div>
            ) : (
              <Dropdown.Divider key={index} />
            ),
          )}
        </DropdownButton>
      )}
      <AlertModal
        show={showDeleteConfirm}
        showCloseButton={true}
        closeModal={() => setShowDeleteConfirm(false)}
        variant='delete'
        message={'alert_delete_form'}
        actionButtons={[
          {
            text: t('button_delete_form'),
            variant: 'red-1',
            onClick: () => applyFormActions('delete_forms'),
          },
          {
            text: t('button_delete_form_data'),
            variant: 'red-1',
            onClick: () => applyFormActions('delete_form_submissions'),
          },
          {
            text: t('button_cancel'),
            variant: 'white-1',
            onClick: () => setShowDeleteConfirm(false),
          },
        ]}
      />

      <AlertModal
        show={showDuplicateModal}
        showCloseButton={true}
        closeModal={() => setShowDuplicateModal(false)}
        actionButtons={[]}
      >
        <Fragment>
          <div>
            {companiesList.map((companyItem, index) => (
              <SingleRadioBoxField
                key={index}
                name={'check-' + index}
                value={selectedCompany?.company._id === companyItem.company._id}
                handleChange={() => setSelectedCompany(companyItem)}
                text={<span>{companyItem.company.companyName}</span>}
                classes='mb-3'
              />
            ))}
          </div>
          <div className='d-flex gap-3'>
            <FormButton
              text='button_cancel'
              variant='white-1'
              onClick={() => setShowDuplicateModal(false)}
            />
            <FormLoadingButton
              text='button_duplicate'
              variant='green-1'
              loading={loading}
              disabled={!selectedCompany}
              onClick={copyForm}
            />
          </div>
        </Fragment>
      </AlertModal>
    </div>
  );
}
FormActions.propTypes = {
  ids: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  isActionButton: PropTypes.bool.isRequired,
  actionsCallBack: PropTypes.func.isRequired,
  actionData: PropTypes.object.isRequired,
};
export default FormActions;
