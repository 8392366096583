import React, { useEffect, useState } from 'react';
import BellIcon from 'assets/images/icons/bell-icon.svg';
import { CallAPI } from 'actions/General';
import ClickOutside from './common-components/ClickOutside';
import { notificationKey } from 'config/config';
import Loader from './common-components/Loader';
import { useTranslation } from 'react-i18next';
import FormButton from './form-components/FormButton';
import toast from 'react-hot-toast';

function Notification() {
  const { t } = useTranslation();
  const [unreadCount, setUnreadCount] = useState(false);
  const [unreadNotificationsIds, setUnreadNotificationsIds] = useState([]);
  const [allNotifications, setAllNotifications] = useState([]);
  const [showNotificationsList, setShowNotificationsList] = useState(false);
  const [loader, setLoader] = useState(false);
  const [exportId, setExportId] = useState('');
  const getAllNotifications = async () => {
    let API = 'GET_NOTIFICATION_HISTORY';
    let APIData = {
      page: 1,
      limit: 25,
    };
    let result = await CallAPI(API, APIData);
    if (result.status) {
      let unreadCount = false;
      let unreadNotificationIdsList = [];
      setAllNotifications(result.data.notifications);
      result.data.notifications.map((singleNotification) => {
        if (!singleNotification.isRead) {
          unreadCount = true;
          unreadNotificationIdsList.push(singleNotification._id);
        }
      });
      setUnreadCount(unreadCount);
      setUnreadNotificationsIds(unreadNotificationIdsList);
    }
  };
  const markNotificationAsRead = async () => {
    setShowNotificationsList(!showNotificationsList);
    if (unreadNotificationsIds.length > 0) {
      let API = 'READ_NOTIFICATION_HISTORY';
      let APIData = {
        notificationIds: unreadNotificationsIds,
      };
      let result = await CallAPI(API, APIData);
      if (result.status) {
        getAllNotifications();
      }
    }
  };
  useEffect(() => {
    getAllNotifications();
    const interval = setInterval(() => {
      getAllNotifications();
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  const downloadExportFile = async (notification, action) => {
    setExportId(notification._id);
    let exportDetails = JSON.parse(notification.details);
    let result = await CallAPI(
      'GET_EXPORT_DETAIL',
      null,
      exportDetails.exportNotify._id,
      setLoader,
      null,
    );
    if (result.status) {
      if (result.data && result.data.exportUrl) {
        if (action === 'download') {
          window.open(result.data.exportUrl, '_blank');
        } else {
          setTimeout(() => {
            navigator.clipboard.writeText(result.data.exportUrl);
          }, 0);

          toast(t('Copied link to clipboard'), {
            style: { borderRadius: '8px', background: '#000', color: '#fff' },
          });
        }
      }
    }
    setExportId('');
  };

  return (
    <div className='notification'>
      <ClickOutside onClick={() => setShowNotificationsList(false)}>
        <div className='notification-icon cursor-pointer'>
          <img onClick={markNotificationAsRead} className={'theme-width-35 mx-3'} src={BellIcon} />
          <span className={`${unreadCount ? 'has-unread-count' : ''}`}></span>
        </div>
        {showNotificationsList && (
          <div className='notifications-list'>
            {allNotifications.map((singleNotification, index) => {
              let singleNotificationDetail = JSON.parse(singleNotification.details);

              let singleNotificationNotifyDetail = singleNotificationDetail.exportNotify
                ? singleNotificationDetail.exportNotify
                : singleNotificationDetail.companyNotify
                ? singleNotificationDetail.companyNotify
                : singleNotificationDetail.formNotify
                ? singleNotificationDetail.formNotify
                : singleNotificationDetail.importNotify;

              if (singleNotificationNotifyDetail)
                return (
                  <div className='notifications-item' key={index}>
                    <span>
                      {t(
                        singleNotification.notificationActions === 'export_failed'
                          ? notificationKey['export_process_failed']
                          : singleNotification.notificationActions.includes('import_failed')
                          ? notificationKey['import_process_failed']
                          : singleNotification.notificationType === 'export' ||
                            singleNotification.notificationActions === 'export_completed' ||
                            singleNotification.notificationType === 'submission'
                          ? notificationKey[
                              singleNotificationNotifyDetail.exportModuleScreen
                                ? singleNotificationNotifyDetail.exportModule +
                                  '_' +
                                  singleNotificationNotifyDetail.exportModuleScreen +
                                  '_' +
                                  singleNotification.notificationActions
                                : singleNotificationNotifyDetail.exportModule +
                                  '_' +
                                  singleNotification.notificationActions
                            ]
                          : singleNotification.notificationType === 'users' ||
                            singleNotification.notificationType === 'forms'
                          ? notificationKey[
                              singleNotification.notificationType +
                                '_' +
                                singleNotification.notificationActions
                            ]
                          : singleNotification.notificationType === 'import'
                          ? notificationKey[singleNotification.notificationActions]
                          : '',
                      )}
                    </span>
                    {(singleNotification.notificationActions === 'export_completed' ||
                      singleNotification.notificationActions ===
                        'download_attachment_completed') && (
                      <div className='d-flex justify-content-between gap-3'>
                        {loader && singleNotification._id === exportId ? (
                          <Loader color='green-1 theme-width-20 theme-height-20' />
                        ) : (
                          <>
                            <FormButton
                              onClick={() => downloadExportFile(singleNotification, 'copy')}
                              text='button_copy_link_to_clipboard'
                              variant='green-1'
                            />
                            <FormButton
                              onClick={() => downloadExportFile(singleNotification, 'download')}
                              text='button_download'
                              variant='green-1'
                            />
                          </>
                        )}
                      </div>
                    )}
                  </div>
                );
            })}
          </div>
        )}
      </ClickOutside>
    </div>
  );
}

export default Notification;
