import React from 'react';
import PropTypes from 'prop-types';
import lockIcon from 'assets/images/icons/lock-grey.svg';
import { useTranslation } from 'react-i18next';
import { formatTimeDifference } from 'config/config';

function Settings({ displayValues }) {
  const { t } = useTranslation();
  return (
    <div className='setting theme-font-jakartaSans-semi-bold theme-size-0_9'>
      <div className='d-flex border border-secondary rounded-1 mb-3'>
        <div className='col-6 theme-background-grey-5 theme-text-black-1 rounded-1 py-2 px-5'>
          {t('text_screen_type')}
        </div>
        <div className='col-6 theme-text-grey-1 py-2 px-5 theme-font-jakartaSans-medium '>
          {displayValues?.type ? displayValues.type : t('Form')}
        </div>
      </div>
      <div className='d-flex border border-secondary rounded-1 mb-3'>
        <div className='col-6 theme-background-grey-5 theme-text-black-1 rounded-1 py-2 px-5'>
          {t('text_version')}
        </div>
        <div className='col-6 theme-text-grey-1 py-2 px-5 theme-font-jakartaSans-medium '>
          {displayValues?.version ? displayValues.version : 1}
        </div>
      </div>
      <div className='d-flex border border-secondary rounded-1 mb-4'>
        <div className='col-6 theme-background-green-1 text-white rounded-1 py-2 px-5'>
          {t('text_status')}
        </div>
        <div className='col-6 theme-text-grey-1 theme-font-jakartaSans-medium d-flex gap-2 py-2 px-5 text-capitalize'>
          {displayValues?.status}
          <img src={lockIcon} className='' alt='Loading...' />
        </div>
      </div>
      <div className='d-flex gap-2 mb-3 theme-text-grey-1'>
        <div className='col-6'>{t('text_last_updated')}:</div>
        <div className='col-6 theme-font-jakartaSans-medium'>
          {displayValues?.lastUpdated && formatTimeDifference(displayValues?.lastUpdated)}
        </div>
      </div>
      <div className='d-flex gap-2 theme-text-grey-1'>
        <div className='col-6'>{t('text_last_updated_by')}:</div>
        <div className='col-6 theme-font-jakartaSans-medium text-capitalize'>
          {displayValues?.lastUpdatedBy}
        </div>
      </div>
    </div>
  );
}
Settings.propTypes = {
  displayValues: PropTypes.object.isRequired,
};
export default Settings;
