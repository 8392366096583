import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { formElementsDefaultProperties } from './utils';
import ConfirmationModal from 'components/common-components/ConfirmationModal';
import DropZone from 'pages/forms/FormCreation/MidSection/DropZone';
import PageComponent from './MidSectionComponents/PageComponent';
import SectionComponent from './MidSectionComponents/SectionComponent';
import UserFieldComponent from './MidSectionComponents/UserFieldComponent';
import Pagination from 'components/common-components/Pagination';
import SupervisorFieldComponent from './MidSectionComponents/SupervisorFieldComponent';
import { updateSavedChanges } from 'config/config';

function FormPreview({
  selectedField,
  setSelectedField,
  formProperties,
  setFormProperties,
  draggedElement,
  direction,
  dataSourcesList,
  propertiesSectionRef,
}) {
  const { t } = useTranslation();
  const [propertiesSectionHeight, setPropertiesSectionHeight] = useState(null);
  const [statusFieldAlreadyAdded, setStatusFieldAlreadyAdded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(Object.keys(formProperties).length - 2);
  useEffect(() => {
    setTotalPages(Object.keys(formProperties).length - 2);
  }, [formProperties]);
  const onComponentDrop = (index) => {
    if (draggedElement && draggedElement.elementType) {
      let properties = cloneDeep(formProperties);
      if (draggedElement.elementType === 'supervisor_status') {
        if (properties.otherDetails.hasStatusFieldInForm) {
          setStatusFieldAlreadyAdded(true);
          return;
        } else {
          properties.otherDetails.hasStatusFieldInForm = true;
        }
      }
      updateSavedChanges(true, 'FORM_DESIGN');

      const currentTimeStamp = Date.now();
      if (draggedElement.elementType !== 'page') {
        let elementToAdd = {
          elementType: draggedElement.elementType,
          properties: cloneDeep(formElementsDefaultProperties[draggedElement.elementType]),
        };
        if (draggedElement.elementType !== 'section')
          elementToAdd = {
            ...elementToAdd,
            uniqueIdToReplace: `field_${currentTimeStamp}`,
          };
        if (elementToAdd.properties.otherProperties)
          elementToAdd.properties.otherProperties.fieldUniqueId = `field_${currentTimeStamp}`;
        let elementAddedIn =
          draggedElement.fieldCategory === 'user-field' ? 'page' + currentPage : 'supervisorFields';
        if (index === -1) {
          properties[elementAddedIn].elements.push(elementToAdd);
        } else {
          let elementsList = properties[elementAddedIn].elements;
          elementsList.splice(index + 1, 0, elementToAdd);
        }

        if (
          !['page', 'section', 'image', 'dataSource', 'externalApi'].includes(
            draggedElement.elementType,
          )
        ) {
          properties.otherDetails.allFormElementsObj = {
            ...properties.otherDetails.allFormElementsObj,
            [elementToAdd.uniqueIdToReplace]: {
              fieldName: 'UnTitled',
              fieldId: elementToAdd.uniqueIdToReplace,
            },
          };
        }
      } else {
        let pageNumber = currentPage + 1;
        let elementToAdd = {
          elementType: 'page',
          properties: cloneDeep(formElementsDefaultProperties.page),
        };
        elementToAdd.properties.otherProperties.fieldUniqueId = `field_${currentTimeStamp}`;

        for (let index = totalPages; index > currentPage; index--) {
          let nextPage = index + 1;
          properties['page' + nextPage] = { elements: properties['page' + index].elements };
        }

        properties['page' + pageNumber] = {
          elements: [elementToAdd],
        };

        const orderedFormProps = Object.keys(cloneDeep(properties))
          .sort()
          .reduce((obj, key) => {
            obj[key] = properties[key];
            return obj;
          }, {});
        properties = cloneDeep(orderedFormProps);
      }
      setFormProperties(properties);
      if (draggedElement.elementType === 'page') {
        setCurrentPage((prev) => prev + 1);
        setSelectedField(null);
      } else {
        if (
          selectedField &&
          selectedField.position > index &&
          index !== -1 &&
          selectedField.type === draggedElement.fieldCategory
        ) {
          setSelectedField((prev) => {
            return { ...prev, position: prev.position + 1 };
          });
        }
      }
    }
  };

  useEffect(() => {
    if (!propertiesSectionRef.current) return;
    const resizeObserver = new ResizeObserver((entries) => {
      // Do what you want to do when the size of the element changes
      if (entries.length) {
        const contentReact = entries[0].contentRect;
        setPropertiesSectionHeight(contentReact.height);
      }
    });
    resizeObserver.observe(propertiesSectionRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  const updateUniqueIdValues = (elementsList) => {
    const currentTimeStamp = Date.now();
    elementsList.map((singleElement, index) => {
      if (
        singleElement.properties.otherProperties &&
        singleElement.properties.otherProperties.fieldUniqueId
      )
        singleElement.properties.otherProperties.fieldUniqueId = `field_${
          currentTimeStamp + index
        }`;
      // singleElement.properties.otherProperties.fieldUniqueId =
      //   'duplicate_' +
      //   (currentPage + 1) +
      //   '_' +
      //   singleElement.properties.otherProperties.fieldUniqueId;
      if (singleElement.elementType === 'dataSource')
        singleElement.properties.basicProperties.bindingList = [];
      delete singleElement.elementGlobalId;
      delete singleElement._id;
    });
    return elementsList;
  };
  const unBindRemovedElements = (elementsList, formProps) => {
    let allRemovedElementsGlobalIds = elementsList.map((singleElement) => {
      return singleElement.elementGlobalId
        ? singleElement.elementGlobalId
        : singleElement.uniqueIdToReplace;
    });
    Object.keys(formProps).map((singlePageElement) => {
      if (singlePageElement.indexOf('page') === 0) {
        formProps[singlePageElement].elements.map((singlePageElement) => {
          if (singlePageElement.elementType === 'dataSource') {
            let updatedBindingList =
              singlePageElement.properties.basicProperties.bindingList.filter(
                (singleBindElement) => {
                  if (
                    !(
                      singleBindElement &&
                      allRemovedElementsGlobalIds.indexOf(singleBindElement.fieldId) > -1
                    )
                  ) {
                    return singleBindElement;
                  }
                },
              );
            singlePageElement.properties.basicProperties.bindingList = updatedBindingList;
          }
        });
      }
    });
    return formProps;
  };

  const changeFormElementOrder = (type, detail, action) => {
    updateSavedChanges(true, 'FORM_DESIGN');
    setSelectedField(null);
    let removedElements = [];
    let deletedFieldType = '';
    let properties = cloneDeep(formProperties);
    let elementAddedIn = type === 'supervisor_field' ? 'supervisorFields' : 'page' + currentPage;
    let currentPageElements = properties[elementAddedIn].elements;
    let isNeededToReplaceElements = true;
    if (action === 'increase' || action === 'decrease') {
      let replacedIndex = action === 'increase' ? detail.position + 1 : detail.position - 1;
      if (type === 'field' || type === 'section' || type === 'supervisor_field') {
        [currentPageElements[detail.position], currentPageElements[replacedIndex]] = [
          currentPageElements[replacedIndex],
          currentPageElements[detail.position],
        ];
      } else {
        isNeededToReplaceElements = false;
        properties['page' + currentPage].elements = properties['page' + replacedIndex].elements;
        properties['page' + replacedIndex].elements = currentPageElements;
      }
    }
    if (type === 'field' || type === 'supervisor_field') {
      if (action === 'delete') {
        deletedFieldType = currentPageElements[detail.position].elementType;
        removedElements.push(currentPageElements[detail.position]);
        currentPageElements.splice(detail.position, 1);
      } else if (action === 'duplicate') {
        let updatedElements = updateUniqueIdValues([
          cloneDeep(currentPageElements[detail.position]),
        ]);
        currentPageElements.splice(detail.position + 1, 0, updatedElements[0]);
      }
    } else if (action === 'delete') {
      if (type === 'page') {
        isNeededToReplaceElements = false;
        removedElements = properties['page' + currentPage].elements;
        if (detail.position === detail.totalPages) {
          delete properties['page' + currentPage];
          setCurrentPage(currentPage - 1);
        } else {
          for (let index = detail.position; index < detail.totalPages; index++) {
            let nextPage = index + 1;
            properties['page' + index].elements = properties['page' + nextPage].elements;
          }
          delete properties['page' + detail.totalPages];
        }
      } else if (type === 'section') {
        for (let index = detail.position; index < currentPageElements.length; index++) {
          if (index !== detail.position && currentPageElements[index].elementType === 'section') {
            break;
          }
          removedElements.push(currentPageElements[index]);
          delete currentPageElements[index];
        }
      }
    } else if (action === 'duplicate') {
      let elementsListToDuplicate = [];
      if (type === 'page') {
        for (let index = 0; index < properties['page' + detail.position].elements.length; index++) {
          const element = properties['page' + detail.position].elements[index];
          elementsListToDuplicate.push(cloneDeep(element));
        }
      } else if (type === 'section') {
        for (let index = detail.position; index < currentPageElements.length; index++) {
          if (index !== detail.position && currentPageElements[index].elementType === 'section') {
            break;
          }
          elementsListToDuplicate.push(cloneDeep(currentPageElements[index]));
        }
      }
      let updatedElements = updateUniqueIdValues(elementsListToDuplicate);
      if (type === 'page') {
        isNeededToReplaceElements = false;
        if (detail.position === detail.totalPages) {
          properties['page' + (detail.totalPages + 1)] = {
            elements: updatedElements,
          };
        } else {
          for (let index = detail.totalPages; index > detail.position; index--) {
            let nextPage = index + 1;
            properties['page' + nextPage] = { elements: properties['page' + index].elements };
          }
          properties['page' + (detail.position + 1)] = { elements: updatedElements };
        }
      } else if (type === 'section') {
        currentPageElements.splice(detail.position + updatedElements.length, 0, ...updatedElements);
      }
    }

    if (isNeededToReplaceElements) properties[elementAddedIn].elements = currentPageElements;
    setFormProperties(properties);
    if (removedElements.length > 0) {
      let updatedFormProperties = unBindRemovedElements(removedElements, properties);
      setFormProperties(updatedFormProperties);
    }
    if (deletedFieldType === 'supervisor_status') {
      properties.otherDetails.hasStatusFieldInForm = false;
      setFormProperties(properties);
    }
  };
  const pageUpdated = (pageNumber) => {
    setCurrentPage(pageNumber);
    setSelectedField(null);
  };
  return (
    <div
      className='fields-playground'
      style={{
        direction: direction,
        ...(propertiesSectionHeight ? { height: `${propertiesSectionHeight - 70}px` } : {}),
      }}
    >
      <div className='mt-1 mb-3 p-3 theme-background-grey-13 custom-shadow text-center'>
        <label className='theme-text-black-1 theme-font-jakartaSans-medium theme-size-1_2'>
          {t('form_user_fields')}
        </label>
      </div>
      <div className='d-flex flex-column page-elements'>
        {formProperties &&
          formProperties['page' + currentPage] &&
          formProperties['page' + currentPage].elements &&
          formProperties['page' + currentPage].elements.map((element, index) => {
            return (
              <div key={index}>
                {element.elementType === 'page' ? (
                  <PageComponent
                    page={element}
                    selectComponent={() => {
                      if (
                        selectedField &&
                        selectedField.type === 'user-field' &&
                        selectedField.position === index
                      )
                        setSelectedField(null);
                      else
                        setSelectedField(
                          cloneDeep({
                            type: 'user-field',
                            position: index,
                            currentPage,
                            elementType: element.elementType,
                          }),
                        );
                    }}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    changeFormElementOrder={changeFormElementOrder}
                  />
                ) : element.elementType === 'section' ? (
                  <SectionComponent
                    elementIndex={index}
                    section={element}
                    selectComponent={() => {
                      if (
                        selectedField &&
                        selectedField.type === 'user-field' &&
                        selectedField.position === index
                      )
                        setSelectedField(null);
                      else
                        setSelectedField(
                          cloneDeep({
                            type: 'user-field',
                            position: index,
                            currentPage,
                            elementType: element.elementType,
                          }),
                        );
                    }}
                    changeFormElementOrder={changeFormElementOrder}
                    isSelected={
                      selectedField &&
                      selectedField.type === 'user-field' &&
                      selectedField.position === index
                        ? true
                        : false
                    }
                  />
                ) : (
                  <UserFieldComponent
                    elementIndex={index}
                    field={element}
                    selectComponent={() => {
                      if (
                        selectedField &&
                        selectedField.type === 'user-field' &&
                        selectedField.position === index
                      )
                        setSelectedField(null);
                      else
                        setSelectedField(
                          cloneDeep({
                            type: 'user-field',
                            position: index,
                            currentPage,
                            elementType: element.elementType,
                          }),
                        );
                    }}
                    changeFormElementOrder={changeFormElementOrder}
                    isSelected={
                      selectedField &&
                      selectedField.type === 'user-field' &&
                      selectedField.position === index
                        ? true
                        : false
                    }
                    dataSourcesList={dataSourcesList}
                  />
                )}
                <DropZone onComponentDrop={() => onComponentDrop(index)} shrink />
              </div>
            );
          })}
      </div>
      <div className='mb-3 p-3 theme-background-grey-13 custom-shadow text-center'>
        <label className='theme-text-black-1 theme-font-jakartaSans-medium theme-size-1_2'>
          {t('form_supervisor_fields')}
        </label>
      </div>
      <div className='d-flex flex-column supervisor-elements'>
        {formProperties &&
          formProperties.supervisorFields.elements.map((element, index) => {
            return (
              <div key={index}>
                <SupervisorFieldComponent
                  elementIndex={index}
                  field={element}
                  selectComponent={() => {
                    if (selectedField && selectedField.position === index) setSelectedField(null);
                    else
                      setSelectedField(
                        cloneDeep({
                          type: 'supervisor-field',
                          position: index,
                          currentPage: 0,
                          elementType: element.elementType,
                        }),
                      );
                  }}
                  changeFormElementOrder={changeFormElementOrder}
                  isSelected={
                    selectedField &&
                    selectedField.type === 'supervisor-field' &&
                    selectedField.position === index
                      ? true
                      : false
                  }
                />
                <DropZone onComponentDrop={() => onComponentDrop(index)} shrink />
              </div>
            );
          })}
      </div>
      <DropZone onComponentDrop={() => onComponentDrop(-1)} />
      {totalPages > 1 && (
        <div className='d-flex justify-content-end'>
          <Pagination
            totalRecords={totalPages}
            currentPage={currentPage}
            displayEntries={1}
            setCurrentPage={pageUpdated}
            type={'form'}
          />
        </div>
      )}
      {statusFieldAlreadyAdded && (
        <ConfirmationModal
          show={statusFieldAlreadyAdded}
          title={t('alert_form_element_status_already_added')}
          message={t('alert_form_element_status_already_added_detail')}
          actionList={[
            {
              color: 'black-1',
              text: t('button_ok'),
              onClick: () => setStatusFieldAlreadyAdded(false),
            },
          ]}
        />
      )}
    </div>
  );
}
FormPreview.propTypes = {
  draggedElement: PropTypes.object,
  selectedField: PropTypes.object,
  direction: PropTypes.string.isRequired,
  formProperties: PropTypes.object.isRequired,
  setFormProperties: PropTypes.func.isRequired,
  setSelectedField: PropTypes.func.isRequired,
  dataSourcesList: PropTypes.array.isRequired,
  propertiesSectionRef: PropTypes.object.isRequired,
};
export default FormPreview;
