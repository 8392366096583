import React, {useState} from 'react';
import PropTypes from 'prop-types';
import MenuItems from 'components/multilevel-dropdown/MenuItems';
import ClickOutside from 'components/common-components/ClickOutside';
import { useTranslation } from 'react-i18next';


const PermissionField = ({
  listValues,
  label,
  icon,
  handleChange,
  classes,
}) => {
  const { t } = useTranslation();

  const [dropdown, setDropdown] = useState(false);

  const toggleDropdown = () => {
    setDropdown((prev) => !prev);
  };

  const handleSubValueChange = (key) => {
    handleChange(key)
  };

  return (
    <div className='permission-dropdown'>
      <ClickOutside onClick={() => setDropdown(false)}>
      <div
        className={`d-flex  align-items-center theme-height-40  gap-2 ${classes}`}
        aria-haspopup="menu"
        aria-expanded={dropdown ? "true" : "false"}
        onClick={() => toggleDropdown()}>
        {t(label)}
        {icon && <img src={icon} className={`theme-height-10 `} />}
      </div>
          
      
        <ul className={`nested-menu-dropdown  ${dropdown ? 'nested-menu-show ' : ''}`}>
          {listValues.map((submenu, index) => {
            return(<MenuItems item={submenu} key={index} depthLevel={1}  onItemClick ={(key) => handleSubValueChange(key) } />)
          })}
        </ul>
      </ClickOutside>
    </div>
  );
};

PermissionField.propTypes = {
  listValues: PropTypes.array.isRequired,
  label: PropTypes.string,
  icon: PropTypes.string,
  handleChange: PropTypes.func,
  classes: PropTypes.string,
};

export default PermissionField;
