import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import formIconBlue from '../../assets/images/icons/form-blue.svg';
import arrowRightIcon from '../../assets/images/icons/long-arrow-right.svg';
import dropIcon from 'assets/images/icons/double-down-arrow-black.svg';
import ActivityMessages from './ActivityMessages.json';
import { useNavigate } from 'react-router-dom';
import TabLayout from 'components/tab-components/TabLayout';
import TabHeader from 'components/tab-components/TabHeader';
import TabBody from 'components/tab-components/TabBody';
import { ActivityFiltersList, activityIcons, getSupervisorStatusValue } from './utils';
import Initials from 'components/common-components/Initials';

const ActivityList = ({
  activitiesList,
  selectedTab,
  setSelectedTab,
  pageNumber,
  setPageNumber,
  totalRecords,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [allActivities, setAllActivities] = useState({});
  useEffect(() => {
    let temp = {};
    activitiesList.forEach((el) => {
      let date = getDate(el.createdAt);
      if (temp[date] != null) {
        temp[date].push(el);
      } else {
        temp[date] = [el];
      }
    });
    setAllActivities(temp);
  }, [activitiesList]);

  const getTime = (dateStr) => {
    const date = new Date(dateStr);

    const formattedTime = new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }).format(date);

    return formattedTime;
  };
  const getDate = (dateStr) => {
    let displayDate = '';
    const date = new Date(dateStr).toLocaleDateString('en-US');
    if (date === new Date().toLocaleDateString('en-US')) displayDate = t('text_today');
    else if (
      date === new Date(new Date().setDate(new Date().getDate() - 1)).toLocaleDateString('en-US')
    )
      displayDate = t('text_yesterday');
    else displayDate = date;
    return displayDate;
  };

  const getValueFromDetails = (details, property) => {
    if (!details) return '';
    else
      return details[property] && details[property][0] && details[property][0]?.title
        ? details[property][0]?.title
        : details[property] && details[property].title
        ? details[property]?.title
        : details[property]
        ? details[property]
        : details.assignedTo
        ? details.assignedTo.title
        : details.title;
  };

  const getDisplayMsg = (activity) => {
    let displayMsg = ActivityMessages[activity.logActions];
    let value = '';
    let details = JSON.parse(activity.details);

    value = getValueFromDetails(
      details,
      activity.logType === 'data_source'
        ? 'dataSource'
        : activity.logType === 'users'||activity.logType === 'invites'
        ? details && details.users
          ? 'users'
          : 'companyUsers'
        : activity.logType === 'submission' &&
          activity.logActions === 'supervisor_updated_submission_approval'
        ? 'details'
        : activity.logType === 'submission'
        ? 'submissions'
        : activity.logActions === 'group_moved_from_segment'
        ? 'groups'
        : activity.logType,
    );
    if (activity.logActions === 'supervisor_updated_submission_approval') {
      value = getSupervisorStatusValue(value);
    }
    let otherArray = [];
    if (activity.logActions === 'group_moved_from_segment') {
      otherArray.push(getValueFromDetails(details.segments[0], 'title'));
      otherArray.push(getValueFromDetails(details.segments[1], 'title'));
    }

    displayMsg = t(displayMsg);
    displayMsg =
      `<span class='theme-font-inter-medium theme-text-black-1 text-capitalize'>
      ${activity.user ? activity.user.firstName + ' ' + activity.user.lastName : 'N/A'}
      </span> ` +
      displayMsg.replace(
        '_',
        `<span class='theme-font-inter-medium theme-text-black-1'>${value}</span>`,
      );
    for (let i = 0; i < otherArray.length; i++) {
      displayMsg = displayMsg.replace(
        '_',
        `<span class='theme-font-inter-medium theme-text-black-1'>${otherArray[i]}</span>`,
      );
    }
    return displayMsg;
  };

  return (
    <TabLayout>
      <TabHeader
        tabList={ActivityFiltersList}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        variant='iconTab'
      />
      <TabBody variant='iconTab'>
        <div className='activity'>
          <div className='activity-tab-body theme-background-white-2 py-2 border'>
            {Object.keys(allActivities).length === 0 && (
              <p className='p-4 theme-text-grey-1 theme-size-1_2 theme-font-jakartaSans-medium'>
                {t('text_no_activities_found')}
              </p>
            )}
            {allActivities &&
              Object.keys(allActivities).map((singleDayActivities, dayIndex) => (
                <div key={dayIndex}>
                  <p className='date-title theme-font-inter-medium theme-text-black-1 text-capitalize'>
                    {singleDayActivities}
                  </p>
                  {allActivities[singleDayActivities].map((activity, index) =>
                    ActivityMessages[activity.logActions] ? (
                      <div key={index} className='activity-item d-flex px-4 pt-4'>
                        <div className='initials'>
                          <Initials
                            removeGuard={true}
                            users={
                              activity.user
                                ? [
                                    {
                                      firstName: activity.user.firstName,
                                      lastName: activity.user.lastName,
                                    },
                                  ]
                                : [{ firstName: '', lastName: '' }]
                            }
                          />
                        </div>
                        <div className='mx-4'>
                          <div className='d-flex gap-2 align-items-center'>
                            <div
                              dangerouslySetInnerHTML={{ __html: getDisplayMsg(activity) }}
                            ></div>
                            {activityIcons[activity.logType] && (
                              <img
                                height={20}
                                width={20}
                                src={activityIcons[activity.logType]}
                                alt='Loading...'
                              />
                            )}
                            <div className='border rounded-2 theme-background-white-1 px-2 theme-text-black-1 theme-size-0_9 text-capitalize'>
                              #{activity.logType}
                            </div>
                          </div>
                          <div className='lh-1 theme-size-0_8 theme-font-jakartaSans-medium'>
                            {getTime(activity.createdAt)}
                          </div>
                          {activity.logActions === 'form_published' && (
                            <div
                              onClick={() => {
                                const formId = JSON.parse(activity.details).forms[0].id;
                                navigate('/forms/edit', {
                                  relative: 'path',
                                  state: { type: 'edit', formId },
                                });
                              }}
                              className='d-inline-flex border rounded-1 gap-2 py-2 px-3 mt-3 align-items-center theme-background-white-1 cursor-pointer'
                            >
                              <img src={formIconBlue} alt='Loading...' />
                              <div>
                                <div className='theme-size-0_9 theme-text-black-1 lh-1 mb-1'>
                                  {t('Research-Field')}
                                </div>
                                <div className='theme-size-0_8 lh-1'>Tap to open</div>
                              </div>
                              <img src={arrowRightIcon} alt='Loading...' />
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div key={index} className='activity-item d-flex px-4 pt-4'>
                        <div className='initials'>
                          <Initials
                            removeGuard={true}
                            users={
                              activity.user
                                ? [
                                    {
                                      firstName: activity.user.firstName,
                                      lastName: activity.user.lastName,
                                    },
                                  ]
                                : [{ firstName: '', lastName: '' }]
                            }
                          />
                        </div>
                        <div className='ms-4'>
                          <div className='d-flex gap-2 align-items-center'>
                            <span className='theme-font-inter-medium theme-text-black-1 text-capitalize'>
                              {activity.user
                                ? activity.user.firstName + ' ' + activity.user.lastName
                                : 'N/A'}
                            </span>
                            <div>{activity.logType} {activity.logActions} is unknown</div>
                            <div className='border rounded-2 theme-background-white-1 px-2 theme-text-black-1 theme-size-0_9 text-capitalize'>
                              #{activity.logType}
                            </div>
                          </div>
                          <div className='lh-1 theme-size-0_8 theme-font-jakartaSans-medium'>
                            {getTime(activity.createdAt)}
                          </div>
                        </div>
                      </div>
                    ),
                  )}
                </div>
              ))}

            <div
              onClick={() =>
                totalRecords !== activitiesList.length ? setPageNumber(pageNumber + 1) : undefined
              }
              className={`d-flex align-items-center justify-content-center gap-3 py-2 border rounded-3 mt-3 ${
                totalRecords === activitiesList.length ? 'cursor-not-allowed' : 'cursor-pointer'
              }`}
            >
              <span className='theme-text-black-1 theme-font-inter-medium theme-size-0_9'>
                {t('text_load_more')}
              </span>
              <img src={dropIcon} alt='Loading...' />
            </div>
          </div>
        </div>
      </TabBody>
    </TabLayout>
  );
};

ActivityList.propTypes = {
  activitiesList: PropTypes.array,
  selectedTab: PropTypes.object.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  totalRecords: PropTypes.number.isRequired,
};

export default ActivityList;
