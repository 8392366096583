import React from 'react';
import PropTypes from 'prop-types';
import { formsVersionTableHeader } from '../utils';
import TableComponent from 'components/table-component/Table';
import { useTranslation } from 'react-i18next';

function VersionHistory({ versions, formId }) {
  const { t } = useTranslation();
  return (
    <div className='p-0 theme-height-530 overflow-auto'>
      <p className='text-center theme-text-black-1 theme-size-1_3 theme-font-inter-medium theme-background-white-2 py-3 mb-0'>
        {t('text_version_history')}
      </p>
      <TableComponent
        data={versions}
        header={formsVersionTableHeader}
        props={{
          type: 'form_version',
          actionsType: 'form_version',
          actionsCallBack: formId,
        }}
        totalRecords={versions.length}
        filterEnabled={false}
        loader={false}
        isFieldSelectionEnabled={false}
        isPaginationEnabled={false}
        isActionsEnabled={false}
        isSelectionEnabled={false}
        selectionKey={''}
        maintainTableHeight={false}
      />
    </div>
  );
}
VersionHistory.propTypes = {
  versions: PropTypes.array.isRequired,
  formId: PropTypes.string.isRequired,
};
export default VersionHistory;
