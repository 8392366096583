import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import crossIcon from 'assets/images/icons/cross.svg';
import editIcon from 'assets/images/icons/edit.svg';
import plusIcon from 'assets/images/icons/plus.svg';
import trashIcon from 'assets/images/icons/trash.svg';
import verticalBoxGroupIcon from 'assets/images/icons/vertical-box-group.svg';
import ColorChangeModal from 'components/common-components/ColorChangeModal';
import FormButton from 'components/form-components/FormButton';
import ResponseSetAddModal from './ResponseSetAddModal';
import ResponseSetListModal from './ResponseSetListModal';
import TextField from 'components/form-components/TextField';
import { CallAPI } from 'actions/General';
import { defaultResponseSet } from '../utils';
import { useTranslation } from 'react-i18next';

const ChoiceListOptionList = ({ optionList, onChangeBasicProperties }) => {
  const { t } = useTranslation();
  const initialState = { responseText: '', value: '', itemColor: '#38CB89FF' };

  const [editIndex, setEditIndex] = useState(-1);
  const [showAddOption, setShowAddOption] = useState(false);
  const [showSelectResponseSet, setShowSelectResponseSet] = useState(false);
  const [newOption, setNewOption] = useState({ ...initialState });
  const [showResponseSetModal, setShowResponseSetModal] = useState(false);
  const [responseSetCreation, setResponseSetCreation] = useState(false);
  const [responseSetList, setResponseSetList] = useState('');
  const handleNewOptionChange = (field, value) => setNewOption({ ...newOption, [field]: value });

  const getAllResponseSetsList = async () => {
    let result = await CallAPI('GET_ALL_RESPONSE_SET', null, null, null, null, null);
    if (result.status) {
      setResponseSetCreation(false);
      setResponseSetList(result.data.choices);
    } else {
      setResponseSetCreation(true);
      setResponseSetList(JSON.stringify({ data: defaultResponseSet }));
    }
  };

  useEffect(() => {
    getAllResponseSetsList();
  }, []);

  const addOptionToList = () => {
    const newOptionList = [...optionList, { ...newOption }];
    onChangeBasicProperties('optionList', newOptionList);
    setNewOption({ ...initialState });
    setShowAddOption(false);

    setFontStyleModalOpen(Array.from(Array(newOptionList.length).keys()).fill(false));
  };

  const removeOption = (index) => {
    const tempOptionList = [...optionList];
    tempOptionList.splice(index, 1);
    onChangeBasicProperties('optionList', [...tempOptionList]);

    setFontStyleModalOpen(Array.from(Array(tempOptionList.length).keys()).fill(false));
  };

  const editOptionToList = (index) => {
    const newOptionList = [...optionList];
    newOptionList[index] = { ...newOption };
    onChangeBasicProperties('optionList', newOptionList);
    setNewOption({ ...initialState });
    setEditIndex(-1);
  };

  const [fontStyleModalOpen, setFontStyleModalOpen] = useState([]);

  const closeFontStyleModals = () => setFontStyleModalOpen([...fontStyleModalOpen].fill(false));

  const toggleFontStyleModal = (index) => {
    const temp = [...fontStyleModalOpen].fill(false);
    temp[index] = !temp[index];
    setFontStyleModalOpen(temp);
  };

  const handleColorChange = (index, color) => {
    const tempOptionList = [...optionList];
    tempOptionList[index].itemColor = color;
    onChangeBasicProperties('optionList', [...tempOptionList]);
  };

  const selectResponseSet = (responseSet) => {
    const newOptionList = [...responseSet];
    onChangeBasicProperties('optionList', newOptionList);

    setFontStyleModalOpen(Array.from(Array(newOptionList.length).keys()).fill(false));
    setShowSelectResponseSet(false);
  };

  const onItemDoubleClick = (index) => {
    closeAllModalActions();
    setEditIndex(index);
    setNewOption({ ...optionList[index] });
  };

  const closeAllModalActions = () => {
    setShowAddOption(false);
    setEditIndex(-1);
    setShowSelectResponseSet(false);
  };

  return (
    <div className='choice-list-view'>
      <div className='list-view'>
        {optionList.length ? (
          <div className='list-item'>
            <div className='icon-col'></div>
            <div className='text-col'>
              <span className='theme-text-black-1'>{t('form_response')}</span>
            </div>
            <div className='text-col'>
              <span className='theme-text-black-1'>{t('form_value')}</span>
            </div>
            <div className='color-col'>
              <div>
                <span className='theme-text-black-1'>{t('form_color')}</span>
              </div>
            </div>
            <div className='icon-col'></div>
          </div>
        ) : (
          <></>
        )}

        {optionList.map((listItem, index) => (
          <div key={index} className='list-item'>
            <div className='icon-col cursor-pointer' onDoubleClick={() => onItemDoubleClick(index)}>
              <img src={verticalBoxGroupIcon} className='index-icon' />
            </div>
            <div className='text-col cursor-pointer' onDoubleClick={() => onItemDoubleClick(index)}>
              <span>{listItem.responseText}</span>
            </div>
            <div className='text-col cursor-pointer' onDoubleClick={() => onItemDoubleClick(index)}>
              <span>{listItem.value}</span>
            </div>
            <div className='color-col'>
              <div className='position-relative'>
                <div
                  className='color-box'
                  style={{ backgroundColor: listItem.itemColor }}
                  onClick={() => toggleFontStyleModal(index)}
                >
                  <img src={editIcon} />
                </div>
                {fontStyleModalOpen[index] && (
                  <ColorChangeModal
                    name='item'
                    closeModal={closeFontStyleModals}
                    styleProperties={listItem ?? {}}
                    handleChange={(_, color) => handleColorChange(index, color)}
                    hideFontSizeOption
                    hideOpacityOption
                  />
                )}
              </div>
            </div>
            <div className='icon-col'>
              <img src={trashIcon} className='cursor-pointer' onClick={() => removeOption(index)} />
            </div>
          </div>
        ))}
      </div>
      {(showAddOption || editIndex > -1) && (
        <div className='d-flex gap-3 align-items-end mb-2'>
          <TextField
            name='responseText'
            value={newOption.responseText}
            displayName='form_response'
            handleChange={handleNewOptionChange}
            shrink
          />
          <TextField
            name='value'
            value={newOption.value}
            displayName='form_value'
            handleChange={handleNewOptionChange}
            shrink
          />
          {editIndex > -1 ? (
            <FormButton
              text='button_edit'
              variant='green-1'
              minWidth
              classes='px-2'
              disabled={newOption.responseText === '' || newOption.value === ''}
              onClick={() => editOptionToList(editIndex)}
            />
          ) : (
            <FormButton
              text='button_add'
              variant='green-1'
              minWidth
              classes='px-2'
              disabled={newOption.responseText === '' || newOption.value === ''}
              onClick={() => addOptionToList()}
            />
          )}
        </div>
      )}
      <div className='d-flex gap-3 align-items-center flex-wrap'>
        <div>
          <label
            className='d-flex gap-1 align-items-center cursor-pointer action-label'
            onClick={() => {
              setNewOption({ ...initialState });
              closeAllModalActions();
              setShowAddOption(!showAddOption);
            }}
          >
            <img src={plusIcon} />
            <span className='theme-size-0_8 theme-text-green-1'>{t('form_add_option')}</span>
          </label>
        </div>
        <div>
          <label
            className='d-flex gap-1 align-items-center cursor-pointer action-label'
            onClick={() => {
              closeAllModalActions();
              onChangeBasicProperties('optionList', []);
            }}
          >
            <img src={crossIcon} className='svg-grey' />
            <span className='theme-size-0_8 theme-text-grey-1'>{t('form_clear')}</span>
          </label>
        </div>
        <div>
          <label
            className='d-flex gap-1 align-items-center cursor-pointer action-label'
            onClick={() => {
              closeAllModalActions();
              setShowSelectResponseSet(!showSelectResponseSet);
            }}
          >
            <img src={plusIcon} />
            <span className='theme-size-0_8 theme-text-green-1'>
              {t('form_select_response_set')}
            </span>
          </label>
        </div>
      </div>
      {showSelectResponseSet && (
        <div className='position-relative'>
          <ResponseSetListModal
            selectResponseSet={selectResponseSet}
            closeModal={() => setShowSelectResponseSet(false)}
            setShowResponseSetModal={setShowResponseSetModal}
            listData={responseSetList}
            updateList={getAllResponseSetsList}
            responseSetCreation={responseSetCreation}
          />
        </div>
      )}
      <ResponseSetAddModal
        listData={responseSetList}
        showResponseSetModal={showResponseSetModal}
        setShowResponseSetModal={setShowResponseSetModal}
        updateList={getAllResponseSetsList}
        responseSetCreation={responseSetCreation}
      />
    </div>
  );
};

ChoiceListOptionList.propTypes = {
  optionList: PropTypes.array.isRequired,
  onChangeBasicProperties: PropTypes.func.isRequired,
};

export default ChoiceListOptionList;
