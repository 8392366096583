import * as yup from 'yup';
import store from '../../store/store';
import { allAccessedRoles } from 'config/config';

import formGreenIcon from 'assets/images/icons/form-green.svg';
import mailIcon from 'assets/images/icons/mail.svg';
import templateIcon from 'assets/images/icons/template.svg';
import icon1 from 'assets/images/form-icons/announcement.svg';
import icon2 from 'assets/images/form-icons/activity-log.svg';
import icon3 from 'assets/images/form-icons/app-screen.svg';
import icon4 from 'assets/images/form-icons/billing.svg';
import icon5 from 'assets/images/form-icons/attendance.svg';
import icon6 from 'assets/images/form-icons/dashboard.svg';
import icon7 from 'assets/images/form-icons/company-settings.svg';
import icon8 from 'assets/images/form-icons/support.svg';

export const formTypeIconValues = [
  { value: 'announcement', labelImage: icon1 },
  { value: 'activity-log', labelImage: icon2 },
  { value: 'app-screen', labelImage: icon3 },
  { value: 'billing', labelImage: icon4 },
  { value: 'attendance', labelImage: icon5 },
  { value: 'dashboard', labelImage: icon6 },
  { value: 'company', labelImage: icon7 },
  { value: 'support', labelImage: icon8 },
];
export const formReportSchema = yup.object({
  toAddress: yup.string().required(),
  subject: yup.string().required(),
  body: yup.string().required(),
  attachmentName: yup.string().required(),
});

export const FormSetupSchema = yup.object({
  title: yup.string().required(),
});

export const FormExportSchema = yup.object({
  apiMethod: yup.string().required(),
  apiUrl: yup.string().required(),
});

export const FormConnectionExportSchema = yup.object({
  settings: yup.object({
    externalDbTableName: yup.string().required(),
  }),
});

export const tabList = [
  {
    id: 1,
    title: 'tabs_design',
    breadcrumbValue: {
      icon: formGreenIcon,
      items: ['sidebar_forms', 'tabs_add_forms', 'tabs_design'],
    },
  },
  {
    id: 2,
    title: 'tabs_report',
    breadcrumbValue: {
      icon: formGreenIcon,
      items: ['sidebar_forms', 'tabs_add_forms', 'tabs_report'],
    },
    disabled: true,
    subTabs: [
      {
        id: 1,
        title: 'tabs_layout_settings',
        isSelected: false,
        titleIcon: templateIcon,
        breadcrumbValue: {
          icon: formGreenIcon,
          items: ['sidebar_forms', 'tabs_add_forms', 'tabs_report', 'tabs_layout_settings'],
        },
      },
      {
        id: 2,
        title: 'tabs_email_settings',
        isSelected: false,
        titleIcon: mailIcon,
        breadcrumbValue: {
          icon: formGreenIcon,
          items: ['sidebar_forms', 'tabs_add_forms', 'tabs_report', 'tabs_email_settings'],
        },
      },
    ],
  },
  {
    id: 3,
    title: 'tabs_permission',
    breadcrumbValue: {
      icon: formGreenIcon,
      items: ['sidebar_forms', 'tabs_add_forms', 'tabs_permission'],
    },
  },
  {
    id: 4,
    title: 'tabs_export',
    breadcrumbValue: {
      icon: formGreenIcon,
      items: ['sidebar_forms', 'tabs_add_forms', 'tabs_export'],
    },
    subTabs: [
      {
        id: 1,
        title: 'API Export',
        isSelected: false,
        titleIcon: mailIcon,
        breadcrumbValue: {
          icon: formGreenIcon,
          items: ['sidebar_forms', 'tabs_add_forms', 'tabs_export', 'API Export'],
        },
      },
      {
        id: 2,
        title: 'Database Export',
        isSelected: false,
        titleIcon: mailIcon,
        breadcrumbValue: {
          icon: formGreenIcon,
          items: ['sidebar_forms', 'tabs_add_forms', 'tabs_export', 'Database Export'],
        },
      },
    ],
  },
  {
    id: 5,
    title: 'tabs_setup',
    breadcrumbValue: {
      icon: formGreenIcon,
      items: ['sidebar_forms', 'tabs_add_forms', 'tabs_setup'],
    },
  },
];

export const permissionTableHeader = [
  {
    name: 'type',
    displayName: 'table_header_access_level',
    type: 'string',
    isRequired: true,
    isSelected: true,
    populateFunc: 'FormAccessLevel',
    translateVal: true,
    callbackFunc: 'permissionRowClick',
  },
  {
    name: 'groups',
    displayName: 'table_header_groups',
    type: 'string',
    isRequired: true,
    isSelected: true,
    translateVal: true,
    populateFunc: 'FormGroupsAccessLevel',
    callbackFunc: 'permissionRowClick',
  },
  {
    name: 'user',
    displayName: 'table_header_users',
    type: 'string',
    isRequired: true,
    isSelected: true,
    translateVal: true,
    populateFunc: 'FormUsersAccessLevel',
    callbackFunc: 'permissionRowClick',
  },
  // {
  //   name: 'folders',
  //   displayName: 'Folders',
  //   type: 'string',
  //   isRequired: true,
  //   isSelected: true,
  //   populateFunc: 'FormFolderAccessLevel',
  //   callbackFunc: 'permissionRowClick',
  // },
  {
    name: 'actions',
    displayName: '',
    type: 'string',
    isRequired: true,
    isSelected: true,
    populateFunc: 'FormActionAccessLevel',
    callbackFunc: 'permissionRowClick',
  },
];
export const formsTabsList = [
  {
    id: 1,
    title: 'tabs_forms',
    value: 'forms',
    breadcrumbValue: { icon: formGreenIcon, items: ['sidebar_forms', 'tabs_forms'] },
  },
  {
    id: 2,
    title: 'tabs_bookmarks',
    value: 'bookmarks',
    breadcrumbValue: { icon: formGreenIcon, items: ['sidebar_forms', 'tabs_bookmarks'] },
    disabled: true,
  },
  // {
  //   id: 3,
  //   title: 'Library',
  //   value: 'library',
  //   breadcrumbValue: { icon: formGreenIcon, items: ['Forms', 'Library'] },
  // },
  {
    id: 4,
    title: 'tabs_archived',
    value: 'archived',
    breadcrumbValue: { icon: formGreenIcon, items: ['sidebar_forms', 'tabs_archived'] },
  },
];
export const formsTableHeader = [
  // {
  //   name: 'edit',
  //   displayName: '',
  //   type: 'string',
  //   isRequired: true,
  //   isSelected: true,
  //   populateFunc: 'populateFormEditIcon',
  //   callbackFunc: 'editForm',
  // },
  {
    name: 'name',
    displayName: 'table_header_form_name',
    type: 'string',
    isRequired: true,
    isSelected: true,
    // associatedKey: 'title',
    populateFunc: 'populateFormTitle',
    callbackFunc: 'formNameClickCallBack',
  },
  {
    name: 'published',
    displayName: 'table_header_published',
    type: 'string',
    isRequired: true,
    isSelected: true,
    associatedKey: 'publishedDate',
    populateFunc: 'populateDate',
  },
  {
    name: 'draft',
    displayName: 'table_header_new_draft',
    type: 'string',
    isRequired: true,
    isSelected: true,
    associatedKey: 'currentVersion.formState',
    populateFunc: 'formState',
  },
  {
    name: 'last_entry',
    displayName: 'table_header_last_entry',
    type: 'string',
    isRequired: true,
    isSelected: true,
    associatedKey: 'last_entry',
  },
  {
    name: 'entries',
    displayName: 'table_header_entries',
    type: 'string',
    isRequired: true,
    isSelected: true,
    associatedKey: 'numberOfEntries',
  },
];
export const archivedFormsTableHeader = [
  {
    name: 'name',
    displayName: 'table_header_form_name',
    type: 'string',
    isRequired: true,
    isSelected: true,
    // associatedKey: 'title',
    populateFunc: 'populateFormTitle',
  },
  {
    name: 'published',
    displayName: 'table_header_published',
    type: 'string',
    isRequired: true,
    isSelected: true,
    associatedKey: 'currentVersion.publishedDate',
    populateFunc: 'populateDate',
  },
  {
    name: 'last_entry',
    displayName: 'table_header_last_entry',
    type: 'string',
    isRequired: true,
    isSelected: true,
    associatedKey: 'last_entry',
  },
  {
    name: 'entries',
    displayName: 'table_header_entries',
    type: 'string',
    isRequired: true,
    isSelected: true,
    associatedKey: 'numberOfEntries',
  },
];
export const formsVersionTableHeader = [
  {
    name: 'version',
    displayName: 'table_header_version',
    type: 'string',
    isRequired: true,
    isSelected: true,
    associatedKey: 'version',
  },
  {
    name: 'published',
    displayName: 'table_header_last_updated',
    type: 'string',
    isRequired: true,
    isSelected: true,
    associatedKey: 'publishedDate',
    populateFunc: 'populateDate',
  },
];

export const checkHasActionPermission = (data, creator) => {
  let auth = store.getState().auth;
  if (auth.profile.userCompanyId === creator || allAccessedRoles.indexOf(auth.profile.role) > -1)
    return true;
  else {
    let isActionAllowed = false;
    data &&
      data.users.map((singleUser) => {
        if (singleUser._id === auth.profile.userCompanyId) isActionAllowed = true;
      });
    if (!isActionAllowed)
      data &&
        data.groups.map((singleGroup) => {
          singleGroup.users.map((singleUser) => {
            if (singleUser._id === auth.profile.userCompanyId) isActionAllowed = true;
          });
        });
    return isActionAllowed;
  }
};

export const validateSqlColumnName = (value) => {
  if (value === '') return true;
  const exp = new RegExp('^[a-zA-Z_][a-zA-Z0-9_]*$');
  return exp.test(value);
};
