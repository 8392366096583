import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import editIcon from 'assets/images/icons/edit.svg';
import trashIcon from 'assets/images/icons/trash.svg';
import verticalBoxGroupIcon from 'assets/images/icons/vertical-box-group.svg';
import ColorChangeModal from 'components/common-components/ColorChangeModal';
import FormButton from 'components/form-components/FormButton';
import TextField from 'components/form-components/TextField';
import { CallAPI } from 'actions/General';

const ResponseSetAddModal = ({
  showResponseSetModal,
  setShowResponseSetModal,
  listData,
  updateList,
  responseSetCreation,
}) => {
  const [optionList, setOptionList] = useState([]);

  const itemDefault = { responseText: '', value: '', itemColor: '#38CB89FF' };
  const [item, setItem] = useState({ ...itemDefault });
  const [editIndex, setEditIndex] = useState(-1);
  const [fontStyleModalOpen, setFontStyleModalOpen] = useState([]);

  const handleChange = (field, value) => setItem({ ...item, [field]: value });

  const addOptionToList = () => {
    const newOptionList = [...optionList, { ...item }];
    setItem({ ...itemDefault });
    setOptionList(newOptionList);

    setFontStyleModalOpen(Array.from(Array(newOptionList.length).keys()).fill(false));
  };
  const removeOption = (index) => {
    const tempOptionList = [...optionList];
    tempOptionList.splice(index, 1);
    setOptionList([...tempOptionList]);

    setFontStyleModalOpen(Array.from(Array(tempOptionList.length).keys()).fill(false));
  };

  const editOptionToList = (index) => {
    const tempOptionList = [...optionList];
    tempOptionList[index] = { ...item };
    setOptionList([...tempOptionList]);

    setItem({ ...itemDefault });
    setEditIndex(-1);
  };

  const cancelEdit = () => {
    setItem({ ...itemDefault });
    setEditIndex(-1);
  };

  const toggleFontStyleModal = (index) => {
    const temp = [...fontStyleModalOpen].fill(false);
    temp[index] = !temp[index];
    setFontStyleModalOpen(temp);
  };
  const closeFontStyleModals = () => setFontStyleModalOpen([...fontStyleModalOpen].fill(false));
  const handleColorChange = (index, color) => {
    const tempOptionList = [...optionList];
    tempOptionList[index].itemColor = color;
    setOptionList([...tempOptionList]);
  };

  const updateResponseSet = async () => {
    let data = JSON.parse(listData).data;
    data = [...data, [...optionList]];
    await CallAPI(
      responseSetCreation ? 'ADD_ALL_RESPONSE_SET' : 'UPDATE_ALL_RESPONSE_SET',
      {
        choices: JSON.stringify({ data }),
      },
      null,
      null,
      null,
      null,
    );
    setShowResponseSetModal(false);
    updateList();
  };

  const onItemDoubleClick = (index) => {
    setEditIndex(index);
    setItem({ ...optionList[index] });
  };

  return (
    <Modal
      show={showResponseSetModal}
      contentClassName='alert-modal'
      aria-labelledby='contained-modal-title-vcenter'
      className='response-set-crud-modal'
      centered
      backdrop='static'
      keyboard={false}
    >
      <div className='alert-content p-4 d-flex flex-column gap-4 justify-content-center align-items-center'>
        {optionList.length ? (
          <div className='choice-list-view'>
            <div className='list-view'>
              <div className='list-item'>
                <div className='icon-col'></div>
                <div className='text-col'>
                  <span className='theme-text-black-1'>Response</span>
                </div>
                <div className='text-col'>
                  <span className='theme-text-black-1'>Value</span>
                </div>
                <div className='color-col'>
                  <div>
                    <span className='theme-text-black-1'>Color</span>
                  </div>
                </div>
                <div className='icon-col'></div>
              </div>

              {optionList.map((listItem, index) => (
                <div key={index} className='list-item'>
                  <div
                    className='icon-col cursor-pointer'
                    onDoubleClick={() => onItemDoubleClick(index)}
                  >
                    <img src={verticalBoxGroupIcon} className='index-icon' />
                  </div>
                  <div
                    className='text-col cursor-pointer'
                    onDoubleClick={() => onItemDoubleClick(index)}
                  >
                    <span>{listItem.responseText}</span>
                  </div>
                  <div
                    className='text-col cursor-pointer'
                    onDoubleClick={() => onItemDoubleClick(index)}
                  >
                    <span>{listItem.value}</span>
                  </div>
                  <div className='color-col'>
                    <div className='position-relative'>
                      <div
                        className='color-box'
                        style={{ backgroundColor: listItem.itemColor }}
                        onClick={() => toggleFontStyleModal(index)}
                      >
                        <img src={editIcon} />
                      </div>
                      {fontStyleModalOpen[index] && (
                        <ColorChangeModal
                          name='item'
                          closeModal={closeFontStyleModals}
                          styleProperties={listItem ?? {}}
                          handleChange={(_, color) => handleColorChange(index, color)}
                          hideFontSizeOption
                          hideOpacityOption
                        />
                      )}
                    </div>
                  </div>
                  <div className='icon-col'>
                    <img
                      src={trashIcon}
                      className='cursor-pointer'
                      onClick={() => removeOption(index)}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className='w-100 d-flex gap-3 align-items-end'>
          <TextField
            name='responseText'
            value={item.responseText}
            displayName='field_response_text'
            handleChange={handleChange}
            shrink
          />
          <TextField
            name='value'
            value={item.value}
            displayName='field_response_value'
            handleChange={handleChange}
            shrink
          />

          {editIndex > -1 ? (
            <Fragment>
              <FormButton
                text='button_cancel'
                variant='white-1'
                minWidth
                classes='px-2'
                onClick={() => cancelEdit()}
              />
              <FormButton
                text='button_edit'
                variant='green-1'
                minWidth
                classes='px-2'
                disabled={item.responseText === '' || item.value === ''}
                onClick={() => editOptionToList(editIndex)}
              />
            </Fragment>
          ) : (
            <FormButton
              text='button_add'
              variant='green-1'
              minWidth
              classes='px-2'
              disabled={item.responseText === '' || item.value === ''}
              onClick={() => addOptionToList()}
            />
          )}
        </div>
        <div className='w-100 d-flex gap-3 justify-content-end'>
          <FormButton
            text='button_cancel'
            variant='white-1'
            minWidth
            classes='px-2'
            onClick={() => setShowResponseSetModal(false)}
          />
          <FormButton
            text='button_save'
            variant='green-1'
            minWidth
            classes='px-2'
            disabled={optionList.length < 2}
            onClick={updateResponseSet}
          />
        </div>
      </div>
    </Modal>
  );
};

ResponseSetAddModal.propTypes = {
  showResponseSetModal: PropTypes.bool.isRequired,
  setShowResponseSetModal: PropTypes.func.isRequired,
  updateList: PropTypes.func.isRequired,
  listData: PropTypes.string.isRequired,
  responseSetCreation: PropTypes.bool.isRequired,
};

export default ResponseSetAddModal;
