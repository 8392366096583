import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CallAPI } from 'actions/General';
import TableComponent from 'components/table-component/Table';
import { userAccessedFormHeader } from '../utils';
import { cloneDeep } from 'lodash';
import dropIcon from 'assets/images/icons/double-down-arrow-black.svg';
import Loader from 'components/common-components/Loader';

function UserFormsAccess({ userId }) {
  const [formsList, setFormsList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [lastCreatedDate, setLastCreatedDate] = useState(null);
  const getAccessedFormsList = async () => {
    let limit = 2;
    let formsAccessResult = await CallAPI(
      'GET_USER_ACCESSED_FORMS',
      {
        userId: userId,
        lastCreatedDate: lastCreatedDate,
        limit,
      },
      null,
      setLoader,
    );
    if (formsAccessResult.status) {
      let accessedForms = cloneDeep(formsList);
      formsAccessResult.data.map((singleItem) => {
        accessedForms.push({ ...singleItem, No: accessedForms.length + 1 });
      });
      if (formsAccessResult.data.length < limit) setLastCreatedDate(null);
      else setLastCreatedDate(accessedForms[accessedForms.length - 1].createdAt);
      setFormsList(accessedForms);
    }
  };
  useEffect(() => {
    getAccessedFormsList();
  }, []);

  return (
    <>
      {loader && <Loader variant='green-1' />}
      <TableComponent
        header={userAccessedFormHeader}
        data={formsList}
        props={{ type: 'userAccessedForms' }}
        totalRecords={0}
        filterEnabled={false}
        loader={loader}
        isFieldSelectionEnabled={false}
        isPaginationEnabled={false}
        isActionsEnabled={false}
        isSelectionEnabled={false}
        selectionKey={''}
        paginationFunction={getAccessedFormsList}
      />
      <div
        onClick={() => (lastCreatedDate !== null ? getAccessedFormsList() : undefined)}
        className={`d-flex align-items-center justify-content-center gap-3 py-2 border rounded-3 mt-3 ${
          lastCreatedDate === null ? 'cursor-not-allowed' : 'cursor-pointer'
        }`}
      >
        <span className='theme-text-black-1 theme-font-inter-medium theme-size-0_9'>Load more</span>
        <img src={dropIcon} alt='Loading...' />
      </div>
    </>
  );
}
UserFormsAccess.propTypes = {
  userId: PropTypes.string,
};
export default UserFormsAccess;
