import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import 'assets/styles/fieldWork/settings.scss';
import TextField from 'components/form-components/TextField';
import searchIcon from 'assets/images/icons/search.svg';
import arrowsRight from 'assets/images/icons/double-arrow-right.svg';
import arrowsLeft from 'assets/images/icons/double-arrow-left.svg';
import FormButton from 'components/form-components/FormButton';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import { CallAPI } from 'actions/General';
import { useTranslation } from 'react-i18next';

const Records = ({ list, searchName }) => {
  const { t } = useTranslation();
  const filteredListLength = list.filter((x) =>
    x.name?.toLowerCase().includes(searchName?.toLowerCase()),
  ).length;

  const listLength = list.length;

  return (
    <div className='records'>
      {listLength === 0 ? (
        <> {t('text_no_records')}</>
      ) : listLength === filteredListLength ? (
        <>
          {t('text_show_all')} {listLength}
        </>
      ) : (
        <>
          {t('Showing')} {filteredListLength} {t('of')} {listLength}
        </>
      )}
    </div>
  );
};

const Box = ({ list, direction, otherList, setList, setOtherList, name }) => {
  const [searchText, setSearchText] = useState('');
  const { t } = useTranslation();

  const handleDragStart = (e, itemData) => {
    e.dataTransfer.setData('text/plain', JSON.stringify(itemData));
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedItemData = e.dataTransfer.getData('text/plain');
    const droppedItem = JSON.parse(droppedItemData);

    let removedFromList = otherList.filter((item) => item.id !== droppedItem.id);
    let addedInList = list;

    if (!list.some((item) => item.id === droppedItem.id)) {
      addedInList = [...list, droppedItem];
    }
    if (!name) {
      setList(addedInList);
      setOtherList(removedFromList);
    } else if (direction === 'forward') {
      setList(addedInList);
      setOtherList(name, removedFromList);
    } else {
      setList(name, addedInList);
      setOtherList(removedFromList);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const moveAll = () => {
    let addedInList = [...otherList, ...list];
    if (!name) {
      setList([]);
      setOtherList(addedInList);
    } else if (direction === 'forward') {
      setList([]);
      setOtherList(name, addedInList);
    } else {
      setList(name, []);
      setOtherList(addedInList);
    }
  };

  const moveOne = (itemId) => {
    let removedFromList = list.filter((item) => item.id !== itemId);
    let addedInList = [...otherList, ...list.filter((item) => item.id === itemId)];
    if (!name) {
      setList(removedFromList);
      setOtherList(addedInList);
    } else if (direction === 'forward') {
      setList(removedFromList);
      setOtherList(name, addedInList);
    } else {
      setList(name, removedFromList);
      setOtherList(addedInList);
    }
  };

  return (
    <>
      <TextField
        value={searchText}
        name='search'
        handleChange={(field, value) => {
          setSearchText(value);
        }}
        placeholder={t('field_search')}
        icon={searchIcon}
        shrink
        classes='w-100'
      />
      <div className='sub-card mt-3'>
        <div className='head'>
          <img
            src={direction === 'forward' ? arrowsRight : arrowsLeft}
            className='back-arrow'
            alt='Loading...'
            onClick={moveAll}
          />
        </div>

        <ul onDrop={handleDrop} onDragOver={handleDragOver}>
          {list
            .filter((x) => x.name?.toLowerCase().includes(searchText?.toLowerCase()))
            .map((item, index) => (
              <li
                draggable
                onDragStart={(e) => handleDragStart(e, item)}
                key={index}
                onClick={() => {
                  moveOne(item.id);
                }}
              >
                {item.name}
              </li>
            ))}
        </ul>
      </div>
      <Records list={list} searchName={searchText} />{' '}
    </>
  );
};

const SelectionComponent = ({
  title,
  onCancel,
  submit,
  name,
  classes,
  selectedDataList,
  setSelectedList,
  buttonsPosition,
  loading,
  error,
  type,
  showButtons = true,
}) => {
  const { t } = useTranslation();
  const [list, setList] = useState([]);

  const GetAllSearchAbleUser = async () => {
    let callName =
      type === 'groups'
        ? 'GET_ALL_GROUPS'
        : type === 'folders'
        ? 'GET_ALL_FOLDERS'
        : 'GET_ALL_SEARCH_ABLE_USERS';
    let result = await CallAPI(callName, {}, null, null, null, null);
    if (result.status) {
      let apiData = type === 'groups' || type === 'folders' ? result.data : result.data.users;
      let allList = [];
      let selectedIds = selectedDataList.map((item) => item.id);
      apiData.map((singleRecord) => {
        if (selectedIds.indexOf(singleRecord._id) === -1)
          allList.push({
            id: singleRecord._id,
            name:
              type === 'groups'
                ? singleRecord.groupName
                : type === 'folders'
                ? singleRecord.name
                : singleRecord.lastName + ', ' + singleRecord.firstName,
          });
      });
      setList(allList);
    }
  };

  useEffect(() => {
    GetAllSearchAbleUser();
  }, [selectedDataList]);

  return (
    <div className={`permissions ${classes}`}>
      <div className='stroke-heading mx-3'>{t(title)}</div>
      <div className='permissions-card row mx-1 my-2 p-3 position-relative'>
        <div className='col-6'>
          <p className='title'>{t('text_not_selected')}</p>

          <Box
            list={list}
            setList={setList}
            otherList={selectedDataList}
            setOtherList={setSelectedList}
            direction='forward'
            name={name}
          />
        </div>
        <div className='col-6'>
          <div className='d-flex justify-content-between'>
            <p className='title'>{t('text_selected')}</p>
            {showButtons && (
              <div className={`d-flex justify-content-end gap-3 ${buttonsPosition}`}>
                <FormButton text='button_cancel' variant='white-1' onClick={onCancel} />
                <FormLoadingButton
                  text='button_save'
                  variant='green-1'
                  onClick={submit}
                  loading={loading}
                />
              </div>
            )}
          </div>

          <Box
            list={selectedDataList}
            setList={setSelectedList}
            otherList={list}
            setOtherList={setList}
            direction='backward'
            name={name}
          />
        </div>
      </div>
      {error && <p className='form-field-error my-3'>{t('error ' + error)}</p>}
    </div>
  );
};

SelectionComponent.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  onCancel: PropTypes.func,
  submit: PropTypes.func,
  setSelectedList: PropTypes.func,
  selectedDataList: PropTypes.array,
  classes: PropTypes.string,
  buttonsPosition: PropTypes.string,
  loading: PropTypes.bool,
  showButtons: PropTypes.bool,
  error: PropTypes.string,
};

Records.propTypes = {
  list: PropTypes.array,
  searchName: PropTypes.string,
};

Box.propTypes = {
  list: PropTypes.array,
  direction: PropTypes.string,
  name: PropTypes.string,
  otherList: PropTypes.array,
  setList: PropTypes.func,
  setOtherList: PropTypes.func,
};

export default SelectionComponent;
