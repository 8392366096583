import React from 'react';
import triangleIcon from 'assets/images/icons/triangle.svg';

const TabDivider = () => {
  return (
    <div className='tab-divider'>
      <img src={triangleIcon} />
      <div />
      <img src={triangleIcon} className='rotate-180' />
    </div>
  );
};

export default TabDivider;
