import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TextField from '../../components/form-components/TextField';
import SingleCheckBoxField from 'components/form-components/SingleCheckBoxField';
import AddDataSourceEntry from 'pages/datasource/datasourceList/AddDataSourceEntry';
import submissionAttachment from 'assets/images/icons/submission-attachment.svg';
import EditDataSourceColumn from 'pages/datasource/datasourceList/EditDataSourceColumn';

const TableHeader = ({
  filterEnabled,
  mediaEnabled,
  tableFields,
  onSelection,
  isActionsEnabled,
  isSelectionEnabled,
  selectionValue,
  debounceFunc,
  filteringData,
  setFilteringData,
  addRow,
  closeRow,
  editedRowData,
  type,
}) => {
  const { t } = useTranslation();
  const [show, setShow] = useState([]);

  const toggleShow = (index) => {
    const tempShow = [...show];
    tempShow[index] = !show[index];
    setShow(tempShow);
  };

  return (
    <thead>
      {filterEnabled && (
        <tr>
          {isSelectionEnabled && <th className='filter-field'></th>}
          {tableFields.map((singleHeaderField, index) => {
            return singleHeaderField.isSelected ? (
              <th key={index} className='filter-field'>
                {singleHeaderField.isFilterAble ? (
                  <TextField
                    value={
                      filteringData[singleHeaderField.name]
                        ? filteringData[singleHeaderField.name]
                        : ''
                    }
                    name={singleHeaderField.name}
                    debounceFunc={debounceFunc}
                    handleChange={setFilteringData}
                    placeholder=''
                    shrink
                    classes='filter-field'
                  />
                ) : (
                  ''
                )}
              </th>
            ) : null;
          })}
          {isActionsEnabled && <th className='filter-field'></th>}
        </tr>
      )}
      <tr>
        {isSelectionEnabled && (
          <th className='table-checkbox'>
            <SingleCheckBoxField
              name={'all'}
              value={selectionValue}
              handleChange={onSelection}
              text={<></>}
              classes=''
            />
          </th>
        )}
        {tableFields.map((singleHeaderField, index) => {
          return singleHeaderField.isSelected ? (
            <th key={index} className='nowrap'>
              <div
                className={`${
                  type === 'submissions' ? 'd-flex gap-3 justify-content-between' : ''
                }`}
              >
                <div className='d-flex gap-2'>
                  {t(singleHeaderField.displayName)}
                  {singleHeaderField && singleHeaderField.isMediaAttached && mediaEnabled ? (
                    <img className='ms-3' src={submissionAttachment} />
                  ) : null}
                  {type === 'data_sources_entries' && (
                    <EditDataSourceColumn
                      show={show[index] ?? false}
                      toggle={() => toggleShow(index)}
                      dataSourceId={null}
                      columnData={{
                        columnName: singleHeaderField.associatedKey,
                        columnId: singleHeaderField.columnId,
                      }}
                      fieldsList={tableFields}
                      refreshList={() => closeRow('edit_delete_column')}
                    />
                  )}
                </div>
                {type === 'submissions' && <div className='border'></div>}
              </div>
            </th>
          ) : null;
        })}
        {isActionsEnabled && <th></th>}
      </tr>
      {addRow && (
        <AddDataSourceEntry
          columnsList={tableFields}
          closeRow={closeRow ? closeRow : null}
          editedRowData={editedRowData}
        />
      )}
    </thead>
  );
};

TableHeader.propTypes = {
  tableFields: PropTypes.array.isRequired,
  onSelection: PropTypes.func,
  filterEnabled: PropTypes.bool.isRequired,
  mediaEnabled: PropTypes.bool,
  isSelectionEnabled: PropTypes.bool,
  isActionsEnabled: PropTypes.bool.isRequired,
  selectionValue: PropTypes.bool.isRequired,
  debounceFunc: PropTypes.func,
  filteringData: PropTypes.object,
  setFilteringData: PropTypes.func,
  addRow: PropTypes.bool,
  closeRow: PropTypes.func,
  editedRowData: PropTypes.object,
  type: PropTypes.string,
};

export default TableHeader;
