import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TableComponent from 'components/table-component/Table';
import { permissionTableHeader } from '../utils';

const PermissionsTable = ({ permissionList, permissionRowClick }) => {
  const { t } = useTranslation();
  const translateValueFunc = (val) => {
   return t(val);
  };
  return (
    <div>
      <div className='head d-flex justify-content-center'>
        {t('text_manage_your_permissions_through_out_the_application')}
      </div>
      <TableComponent
        header={permissionTableHeader}
        data={permissionList}
        props={{
          type: 'form_permissions',
          permissionRowClick,
          translateValueFunc: translateValueFunc,
        }}
        totalRecords={0}
        filterEnabled={false}
        loader={false}
        isFieldSelectionEnabled={false}
        isPaginationEnabled={false}
        isActionsEnabled={false}
        isSelectionEnabled={false}
        selectionKey={null}
        paginationFunction={null}
        onSelection={null}
        selectedRows={[]}
        maintainTableHeight={false}
      />
    </div>
  );
};

PermissionsTable.propTypes = {
  permissionList: PropTypes.array.isRequired,
  permissionRowClick: PropTypes.func.isRequired,
};

export default PermissionsTable;
