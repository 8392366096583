import Dropdown from './Dropdown';
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const MenuItems = ({ item, icon, depthLevel, onItemClick }) => {
  const { t } = useTranslation();
  const [dropdown, setDropdown] = useState(false);
  let ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, [dropdown]);

  const onMouseEnter = () => {
    setDropdown(true);
  };

  const onMouseLeave = () => {
    setDropdown(false);
  };

  const toggleDropdown = () => {
    console.log('toggleDropdown depthLevel =', depthLevel);

    //setDropdown((prev) => !prev);
  };

  const closeDropdown = () => {
    console.log('closeDropdown depthLevel =', depthLevel);
    //dropdown && setDropdown(false);
  };

  const clickItem = () => {
    onItemClick(item.key);
    //setDropdown((prev) => !prev);
  };

  return (
    <li
      className='nested-menu-items '
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={closeDropdown}
    >
      {item.subMenu ? (
        <>
          <div
            className={`d-flex px-2 align-items-center justify-content-between theme-height-40 ${
              dropdown ? 'nested-menu-items-hover' : ''
            } `}
            aria-haspopup='menu'
            aria-expanded={dropdown ? 'true' : 'false'}
            onClick={() => toggleDropdown()}
          >
            {t(item.displayName)}
            {depthLevel > 0 && icon && <img src={icon} className={`theme-height-10 rotate-270 `} />}
          </div>

          <Dropdown
            depthLevel={depthLevel}
            submenus={item.subMenu}
            dropdown={dropdown}
            icon={icon}
            onItemClick={onItemClick ? onItemClick : null}
          />
        </>
      ) : item.isCheckbox ? (
        <div
          className={`d-flex px-2 align-items-center gap-2 theme-height-40 ${
            dropdown ? 'nested-menu-items-hover' : ''
          } `}
          aria-haspopup='menu'
          aria-expanded={dropdown ? 'true' : 'false'}
          onClick={() => clickItem()}
        >
          <input
            className='form-check-input theme-height-20 theme-width-20'
            type='checkbox'
            checked={item.isChecked}
          />

          {t(item.displayName)}
        </div>
      ) : (
        <div
          className={`d-flex px-2 align-items-center justify-content-between theme-height-40 ${
            dropdown ? 'nested-menu-items-hover' : ''
          } `}
          aria-haspopup='menu'
          aria-expanded={dropdown ? 'true' : 'false'}
          onClick={() => clickItem()}
        >
          {t(item.displayName)}
        </div>
      )}
    </li>
  );
};

MenuItems.propTypes = {
  item: PropTypes.object.isRequired,
  depthLevel: PropTypes.number,
  icon: PropTypes.string,
  onItemClick: PropTypes.func,
};

export default MenuItems;
