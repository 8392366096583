import React, { useState } from 'react';
import PropTypes from 'prop-types';
import selectArrow from 'assets/images/select-arrow.png';
import CompanyDeleteModal from './CompanyDeleteModal';
import Error from 'components/Error';
import FormButton from 'components/form-components/FormButton';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import SelectField from 'components/form-components/SelectField';
import TextField from 'components/form-components/TextField';
import { companyDetailSchema } from '../utils';
import { isPermissionAvailable } from 'config/permissionUtils';
import { permissionKeys } from 'config/config';
import { CallAPI } from 'actions/General';
import { countryList } from 'pages/auth-pages/utils';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const CompanyDetails = ({ companyDetails }) => {
  const auth = useSelector((state) => state.auth);
  const { t } = useTranslation();

  const [values, setValues] = useState({
    companyName: companyDetails.companyName ? companyDetails.companyName : '',
    nameOnMidani: companyDetails.nameOnMidani ? companyDetails.nameOnMidani : '',
    domain: companyDetails.domain ? companyDetails.domain : '',
    website: companyDetails.website ? companyDetails.website : '',
    countryCode: companyDetails.countryCode ? companyDetails.countryCode : '',
    companyCode: companyDetails.companyCode ? companyDetails.companyCode : '',
  });
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const handleChange = (field, value) => {
    setTouched({ ...touched, [field]: true });
    setErrors({ ...errors, [field]: undefined });
    setValues({
      ...values,
      [field]: value,
    });
  };
  const [loader, setLoader] = useState(false);
  const [deleteCompanyModal, setDeleteCompanyModal] = useState(false);
  const [apiError, setApiError] = useState('');

  const submit = async () => {
    try {
      await companyDetailSchema.validate(values, { abortEarly: false });
      await CallAPI('UPDATE_COMPANY_DETAIL', values, null, setLoader, setApiError);
    } catch (error) {
      const schemaErrors = {};
      error.inner?.forEach((err) => {
        schemaErrors[err.path] = err.message;
      });

      setErrors(schemaErrors);
    }
  };

  return (
    <div className='px-4 py-3'>
      {apiError && (
        <div className='mb-2'>
          <Error msg={apiError} />
        </div>
      )}
      <TextField
        name='companyName'
        displayName='field_official_company_name'
        placeholder='field_official_company_name'
        value={values.companyName}
        error={errors.companyName}
        touched={touched.companyName}
        handleChange={handleChange}
        classes='mb-3'
        isHorizontal={true}
        horizontalLabelClass={'min-width-176'}
        shrinkFieldOnly
      />
      <TextField
        name='companyCode'
        displayName='field_company_code'
        placeholder='field_company_code'
        value={values.companyCode}
        error={errors.companyCode}
        touched={touched.companyCode}
        handleChange={handleChange}
        classes='mb-3 border-0'
        isHorizontal={true}
        isDisplayAbleOnly={true}
        disabled={true}
        shrinkFieldOnly
        horizontalLabelClass={'min-width-176'}
      />

      <TextField
        name='nameOnMidani'
        displayName='field_name_on_midani'
        placeholder='field_name_on_midani'
        value={values.nameOnMidani}
        error={errors.nameOnMidani}
        touched={touched.nameOnMidani}
        handleChange={handleChange}
        classes='mb-3'
        isHorizontal={true}
        horizontalLabelClass={'min-width-176'}
        shrinkFieldOnly
      />

      <SelectField
        handleChange={handleChange}
        name='countryCode'
        listValues={countryList}
        placeholder={'field_country'}
        label={
          countryList.filter((singleCountry) => values.countryCode === singleCountry.value).length >
          0
            ? countryList.filter((singleCountry) => values.countryCode === singleCountry.value)[0]
                .displayValue
            : countryList[0].displayValue
        }
        icon={selectArrow}
        variant='white-1'
        classes='mb-3'
        menuClasses='w-100'
        isAutoClose={true}
        isHorizontal={true}
      />

      <TextField
        name='domain'
        displayName='field_email_domain'
        placeholder='field_email_domain'
        value={values.domain}
        error={errors.domain}
        touched={touched.domain}
        handleChange={handleChange}
        classes='mb-3'
        isHorizontal={true}
        horizontalLabelClass={'min-width-176'}
        shrinkFieldOnly
      />

      <TextField
        name='website'
        displayName='field_website'
        placeholder='field_website'
        value={values.website}
        error={errors.website}
        touched={touched.website}
        handleChange={handleChange}
        classes='mb-3'
        isHorizontal={true}
        horizontalLabelClass={'min-width-176'}
        shrinkFieldOnly
      />

      {auth.profile && auth.profile.role && auth.profile && auth.profile.role === 'owner' && (
        <div className='d-flex align-items-center'>
          <label className='min-width-176'>{t('text_delete_company')}</label>
          <FormLoadingButton
            loading={false}
            text='button_delete'
            variant='red-1'
            onClick={() => setDeleteCompanyModal(true)}
          />
        </div>
      )}

      {isPermissionAvailable(permissionKeys.system_company_setup_update) && (
        <div className='mt-3 w-100 d-flex justify-content-end gap-3'>
          <FormButton text='button_cancel' variant='white-1' />
          <FormLoadingButton
            loading={loader}
            onClick={submit}
            text='button_save'
            variant='green-1'
          />
        </div>
      )}
      {deleteCompanyModal && (
        <CompanyDeleteModal showModal={deleteCompanyModal} setShowModal={setDeleteCompanyModal} />
      )}
    </div>
  );
};
CompanyDetails.propTypes = {
  companyDetails: PropTypes.object,
};
export default CompanyDetails;
