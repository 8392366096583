import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import { hexToRgba, rgbaToHex } from 'hex-and-rgba';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import { ColorReducer } from 'redux/colors';
import { useTranslation } from 'react-i18next';
const ColorReducerActions = ColorReducer.actions;

function CustomColorPicker({ pickerColor, handleChange }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isRecentUpdated, setIsRecentUpdated] = useState(false);
  const recentColors = useSelector((state) => state.colors.recentColors);

  const [recentColorsList, setRecentColorsList] = useState([]);
  let localColors = [
    '#000000FF',
    '#FFFFFFFF',
    '#636874FF',
    '#38CB89FF',
    '#FFA600FF',
    '#FF5630FF',
    '#377DFFFF',
    '#EC3434FF',
    '#56CCF2FF',
  ];

  useEffect(() => {
    setRecentColorsList(recentColors);
  }, recentColors);
  const updateRecentColors = (color) => {
    color = rgbaToHex(color.rgb.r, color.rgb.g, color.rgb.b, color.rgb.a);
    let recentColors = cloneDeep(recentColorsList);

    if (isRecentUpdated) {
      recentColors[recentColors.length - 1] = color;
    } else {
      if (recentColors.length === 9) recentColors.shift();
      setIsRecentUpdated(true);
      recentColors.push(color);
    }
    setRecentColorsList(recentColors);
    dispatch(ColorReducerActions.updateRecentColors({ recentColors }));
  };

  return (
    <div>
      <SketchPicker
        width='100%'
        color={pickerColor}
        className='mt-2'
        onChange={(color) => {
          const { r, g, b, a } = color.rgb;
          handleChange(r, g, b, a);
        }}
        onChangeComplete={(color) => {
          updateRecentColors(color);
        }}
      />
      <p className='theme-font-inter-medium theme-size-0_9 theme-text-grey-1 my-3'>
        {t('text_recent_colors')}
      </p>
      <ColorsField
        handleChange={handleChange}
        colors={recentColorsList}
        updateRecentColors={updateRecentColors}
      />
      <p className='theme-font-inter-medium theme-size-0_9 theme-text-grey-1 my-3'>
        {t('text_local_colors')}
      </p>
      <ColorsField
        handleChange={handleChange}
        colors={localColors}
        updateRecentColors={updateRecentColors}
      />
    </div>
  );
}
CustomColorPicker.propTypes = {
  pickerColor: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};
export default CustomColorPicker;

const ColorsField = ({ colors, handleChange, updateRecentColors }) => {
  return (
    <div className='colors-list'>
      {colors &&
        colors.map((singleColor, key) => (
          <span
            className='color-for-selection'
            style={{ '--color': singleColor }}
            key={key}
            onClick={() => {
              let color = hexToRgba(singleColor);
              handleChange(color[0], color[1], color[2], color[3]);
              updateRecentColors({ rgb: { r: color[0], g: color[1], b: color[2], a: color[3] } });
            }}
          ></span>
        ))}
    </div>
  );
};
ColorsField.propTypes = {
  colors: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  updateRecentColors: PropTypes.func.isRequired,
};
