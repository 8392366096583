import React, { useState, useEffect } from 'react';
import FormButton from 'components/form-components/FormButton';
import PropTypes from 'prop-types';
import { CallAPI } from 'actions/General';
import { useTranslation } from 'react-i18next';
import AlertModal from 'components/common-components/AlertModal';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import dotsIcon from 'assets/images/icons/table-icons.svg';
import dropdownIcon from 'assets/images/icons/dropdown-arrow-white.svg';

function TrashActions({ ids, actionsCallBack, isActionButton, type }) {
  const { t } = useTranslation();
  const [showAlert, setShowAlert] = useState(false);
  const [alertConfirmType, setAlertConfirmType] = useState('');
  const [displayedMenu, setDisplayedMenu] = useState([]);

  const makeAPIData = (action) => {
    let APICall = 'TRASH_SUBMISSIONS_ACTIONS';
    let APIData = {};
    if (type === 'trash_forms' || type === 'row_trash_forms') {
      APICall = 'TRASH_FORMS_ACTIONS';
      APIData = {
        action: action,
        forms: ids,
      };
    } else {
      let submissionList = [];
      ids.map((singleSelectedRecord) => {
        submissionList.push({
          form: {
            id: singleSelectedRecord.form_id,
            title: singleSelectedRecord.title,
          },
          submission: {
            id: singleSelectedRecord.id,
            title: singleSelectedRecord.title,
          },
        });
      });
      APIData = {
        action: action,
        submissions: submissionList,
      };
    }
    return { APICall, APIData };
  };
  const actionFunction = async (action) => {
    let { APICall, APIData } = makeAPIData(action);
    await CallAPI(APICall, APIData, null, null, null, null);
    setShowAlert(false);
    actionsCallBack('delete');
  };

  useEffect(() => {
    let submissionActionsList = [
      {
        allowedFrom: [
          'trash_forms',
          'row_trash_forms',
          'trash_submissions',
          'row_trash_submissions',
        ],
        name: 'action_delete_permanently',
        permission: true,
        action: () => {
          setShowAlert(true);
          setAlertConfirmType(
            type === 'trash_forms' || type === 'row_trash_forms'
              ? 'delete_forms'
              : 'delete_submissions',
          );
        },
      },
      {
        allowedFrom: [
          'trash_forms',
          'row_trash_forms',
          'trash_submissions',
          'row_trash_submissions',
        ],
        name: 'action_restore',
        permission: true,
        action: () => {
          setShowAlert(true);
          let actionType =
            type === 'trash_forms' || type === 'row_trash_forms'
              ? 'form_restore'
              : 'submissions_restore';
          if (actionType === 'submissions_restore') {
            for (let index = 0; index < ids.length; index++) {
              const singleSelectedRecord = ids[index];
              if (singleSelectedRecord.form_status === 'trash') {
                actionType = 'form_in_trash';
              }
            }
          }
          setAlertConfirmType(actionType);
        },
      },
    ];
    let menuList = submissionActionsList.filter(
      (item) => item.allowedFrom.indexOf(type) > -1 && item.permission,
    );
    setDisplayedMenu(menuList);
  }, [type, ids]);
  return (
    <div className='dropdown-actions'>
      {displayedMenu && displayedMenu.length > 0 && (
        <DropdownButton
          disabled={ids.length > 0 ? false : true}
          as={ButtonGroup}
          drop='bottom'
          variant='secondary'
          title={
            <>
              {isActionButton ? (
                <img src={dotsIcon} alt='Loading...' />
              ) : (
                <FormButton text='button_action' variant='green-1' icon={dropdownIcon} />
              )}
            </>
          }
        >
          {displayedMenu.map((button, index) => (
            <Dropdown.Item
              onClick={button.action}
              key={index}
              className='items theme-size-1 theme-text-grey-1 px-3 py-2 cursor-pointer'
            >
              {t(button.name)}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      )}
      <AlertModal
        show={showAlert}
        showCloseButton={true}
        closeModal={() => setShowAlert(false)}
        variant='delete'
        message={t(
          alertConfirmType === 'delete_forms'
            ? 'alert_delete_form_with_all_data'
            : alertConfirmType === 'delete_submissions'
            ? 'alert_delete_submission_permanently'
            : alertConfirmType === 'form_restore'
            ? 'alert_form_restore_with_data'
            : alertConfirmType === 'submissions_restore'
            ? 'Are you sure you want to restore submission?'
            : alertConfirmType === 'form_in_trash'
            ? 'alert_form_in_trash'
            : '',
        )}
        actionButtons={
          alertConfirmType === 'form_in_trash'
            ? [{ text: t('button_ok'), variant: 'green-1', onClick: () => setShowAlert(false) }]
            : [
                {
                  text: t('button_confirm'),
                  variant: 'red-1',
                  onClick: () => actionFunction(alertConfirmType),
                },
                {
                  text: t('button_cancel'),
                  variant: 'white-1',
                  onClick: () => setShowAlert(false),
                },
              ]
        }
      />
    </div>
  );
}
TrashActions.propTypes = {
  ids: PropTypes.array.isRequired,
  actionsCallBack: PropTypes.func.isRequired,
  isActionButton: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
};
export default TrashActions;
