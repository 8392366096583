import React, { useEffect, useState } from 'react';
import TextField from 'components/form-components/TextField';
import SingleCheckBoxField from 'components/form-components/SingleCheckBoxField';
import { useTranslation } from 'react-i18next';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import { CallAPI } from 'actions/General';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { uploadImage } from 'pages/company/utils';
import { isPermissionAvailable } from 'config/permissionUtils';
import { permissionKeys } from 'config/config';
import UploadSectionImage from 'components/common-components/UploadSectionImage';

const AppWelcomeScreen = ({ companyDetails, getCompanyDetail }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [newLogo, setNewLogo] = useState(null);
  const [values, setValues] = useState({
    logo: null,
    tagline: '',
    description: '',
    termsConditions: false,
    termsConditionsLink: '',
    privacyPolicy: false,
    privacyPolicyLink: '',
  });

  useEffect(() => {
    if (companyDetails && companyDetails.welcomeScreen) {
      setValues({
        logo: companyDetails.welcomeScreen.media
          ? {
              logo: companyDetails.welcomeScreen.media.downloadUrl,
              mediaId: companyDetails.welcomeScreen.media._id,
              name: companyDetails.welcomeScreen.media.imageName,
              customFolderId: companyDetails.welcomeScreen.media.customFolderId,
            }
          : null,
        tagline: companyDetails.welcomeScreen.title || '',
        description: companyDetails.welcomeScreen.description || '',
        termsConditions: companyDetails.welcomeScreen.termsConditionsLink ? true : false,
        termsConditionsLink: companyDetails.welcomeScreen.termsConditionsLink || '',
        privacyPolicy: companyDetails.welcomeScreen.privacyPolicyLink ? true : false,
        privacyPolicyLink: companyDetails.welcomeScreen.privacyPolicyLink || '',
      });
    }
  }, [companyDetails]);
  const deleteLogo = async () => {
    await CallAPI(
      'DELETE_MEDIA',
      {
        folderType: 'logo',
        mediaId: values.logo.mediaId,
        mediaType: 'image',
        imageName: values.logo.name,
        formId: null,
        versionId: null,
        customFolderId: values.logo.customFolderId,
      },
      null,
      setLoading,
      null,
      null,
    );
  };
  const submit = async () => {
    if (newLogo) {
      if (values.logo !== null) await deleteLogo();
      let response = await CallAPI(
        'UPLOAD_COMPANY_LOGO',
        {
          imageName: uuidv4() + '-' + newLogo.name,
          mediaType: 'image',
        },
        null,
        setLoading,
        null,
        null,
      );
      if (response.status) {
        await uploadImage(newLogo, response.data, updateWelcomeScreen);
      }
    } else updateWelcomeScreen();
  };

  const updateWelcomeScreen = async (id) => {
    let payload = {
      media: id,
      title: values.tagline,
      description: values.description,
      termsConditionsLink: values.termsConditionsLink,
      privacyPolicyLink: values.privacyPolicyLink,
    };
    await CallAPI('UPDATE_WELCOME_SCREEN', payload, null, setLoading, null, null);
    getCompanyDetail();
  };

  const handleChange = (field, value) => {
    setValues({ ...values, [field]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewLogo(file);
    }
  };

  return (
    <div className='branding'>
      <div className='app-screen'>
        <div>
          <UploadSectionImage
            selectedImage={newLogo}
            handleFileChange={handleFileChange}
            label='field_upload_image'
          />
          <TextField
            placeholder='field_title'
            name='tagline'
            icon={null}
            handleChange={handleChange}
            value={values.tagline}
            classes='my-3 w-100 tagline-text theme-text-black-1'
          />

          <TextField
            placeholder='field_description'
            name='description'
            icon={null}
            handleChange={handleChange}
            value={values.description}
            classes='my-3 w-100 description-text'
          />

          <SingleCheckBoxField
            name='termsConditions'
            value={values.termsConditions}
            handleChange={handleChange}
            text={<span>{t('field_add_your_company_terms_conditions')}</span>}
            classes='mt-3 mb-3'
          />

          <TextField
            name='termsConditionsLink'
            placeholder='field_insert_your_link'
            icon={null}
            handleChange={handleChange}
            value={values.termsConditionsLink}
            classes='mb-3 w-100'
            disabled={values.termsConditions ? false : true}
          />

          <SingleCheckBoxField
            name='privacyPolicy'
            value={values.privacyPolicy}
            handleChange={handleChange}
            text={<span>{t('field_add_your_company_privacy_policy')}</span>}
            classes='mb-3'
          />

          <TextField
            name='privacyPolicyLink'
            placeholder='field_insert_your_link'
            icon={null}
            handleChange={handleChange}
            value={values.privacyPolicyLink}
            classes='w-100 theme-text-0_8'
            disabled={values.privacyPolicy ? false : true}
          />
        </div>
        <div className='d-flex justify-content-center'>
          <div className='preview-box theme-height-650 theme-width-290 position-relative'>
            <div className='mobile-screen d-flex align-items-center flex-column position-absolute theme-height-504 theme-width-234 text-center px-3 text-break'>
              <div className='logo mt-5 rounded-circle'>
                {values.logo && (
                  <img className='rounded-circle' src={values.logo.logo} alt='Loading...' />
                )}
              </div>
              <div className='tagline-text theme-text-black-1 my-4 '>{values.tagline}</div>
              <div className='description-text text-break'>{values.description}</div>
              <div className='mt-auto mb-4 mx-3 privacy-policy theme-size-0_7 text-center'>
                {values.termsConditions && (
                  <>
                    {t('text_terms_condition')}{' '}
                    <a
                      className='theme-text-blue-1'
                      href={values.termsConditionsLink}
                      target='blank'
                    >
                      {t('text_link')}
                    </a>
                  </>
                )}{' '}
                {values.privacyPolicy && (
                  <>
                    {t('text_read_our_privacy_policy')}{' '}
                    <a
                      className='theme-text-blue-1'
                      href={values.termsConditionsLink}
                      target='blank'
                    >
                      {t('text_link')}
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isPermissionAvailable(permissionKeys.system_company_setup_update) && (
        <div className='d-flex justify-content-end mx-5 my-3'>
          <FormLoadingButton
            text='button_save'
            variant='green-1'
            onClick={submit}
            loading={loading}
          />
        </div>
      )}
    </div>
  );
};

AppWelcomeScreen.propTypes = {
  companyDetails: PropTypes.object,
  getCompanyDetail: PropTypes.func,
};
export default AppWelcomeScreen;
