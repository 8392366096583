import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import eyeIcon from '../../assets/images/icons/eye-off.svg';
import phoneIcon from '../../assets/images/icons/phone.svg';
import Error from '../../components/Error';
import FormButton from '../../components/form-components/FormButton';
import FormLoadingButton from '../../components/form-components/FormLoadingButton';
import MobileNumberField from '../../components/form-components/MobileNumberField';
import PasswordField from '../../components/form-components/PasswordField';
import { loginSchema, userLogin } from './utils';
import { useTranslation } from 'react-i18next';

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [values, setValues] = useState({
    countryCode: '+966',
    mobileNumber: '',
    password: '',
  });

  const [apiError, setApiError] = useState('');
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (field, value) => {
    setTouched({ ...touched, [field]: true });
    setErrors({ ...errors, [field]: undefined });
    setValues({
      ...values,
      [field]: value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setTouched({});
    try {
      await loginSchema.validate(values, { abortEarly: false });

      const email = values.countryCode + values.mobileNumber + process.env.REACT_APP_EMAIL_SUFFIX;
      await userLogin({ email, password: values.password }, setApiError, navigate);
    } catch (error) {
      const schemaErrors = {};
      error.inner?.forEach((err) => {
        schemaErrors[err.path] = err.message;
      });

      setErrors(schemaErrors);
    }
    setLoading(false);
  };

  return (
    <div className='auth-form-container'>
      <h3 className='main-heading'>{t('text_welcome_to_midani')}</h3>
      <p className='sub-heading'>{t('text_login_to_company')}</p>
      {apiError ? <Error msg={apiError} /> : <></>}{' '}
      <MobileNumberField
        fieldProps={{ name: 'mobileNumber', placeholder: '123123123' }}
        displayName='field_phone_number'
        icon={phoneIcon}
        value={{ countryCode: values.countryCode, mobileNumber: values.mobileNumber }}
        error={errors.mobileNumber}
        touched={touched.mobileNumber}
        handleChange={handleChange}
        classes='mb-3'
      />
      <PasswordField
        name='password'
        displayName='field_password'
        placeholder='field_enter_password'
        icon={eyeIcon}
        value={values.password}
        error={errors.password}
        touched={touched.password}
        handleChange={handleChange}
        onEnter={onSubmit}
        classes='mb-3'
      />
      <div className='space-10'></div>
      <FormLoadingButton
        text='button_login'
        variant='green-1'
        loading={loading}
        onClick={onSubmit}
      />
      <div className='space-20'></div>
      <p className='helper-text text-left'>
        {t('text_forgot_password')}{' '}
        <NavLink to='/reset_password'>
          <span className='theme-text-green-1'>{t('text_reset_password')}</span>
        </NavLink>
      </p>
      <div className='space-20'></div>
      <FormButton
        text='button_create_account'
        variant='white-1'
        onClick={() => navigate('/register', { relative: 'path' })}
      />
    </div>
  );
};

export default Login;
