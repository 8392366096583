import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import UpdatePermissions from './UpdatePermissions';
import PermissionsTable from './PermissionsTable';
import mobileIcon from 'assets/images/icons/app-screen-green.svg';
import userIcon from 'assets/images/icons/users-circle-green.svg';
import toolsIcon from 'assets/images/icons/tools-green.svg';
import testersIcon from 'assets/images/icons/users-circle-green.svg';

const Permissions = ({ formDetails, getFormDetail }) => {
  const [permissionList, setPermissionList] = useState([]);
  const [selectedPermission, setSelectedPermission] = useState(null);

  useEffect(() => {
    setPermissionList([
      {
        key: 'appUser',
        icon: mobileIcon,
        access: 'text_app_users_input_data',
        ...formDetails?.appUser,
      },
      {
        key: 'supervisor',
        icon: userIcon,
        access: 'text_supervisors_view_data',
        ...formDetails?.supervisor,
      },
      {
        key: 'designer',
        icon: toolsIcon,
        access: 'text_designers_edit_form_data',
        ...formDetails?.designer,
      },
      {
        key: 'testers',
        icon: testersIcon,
        access: 'text_testers_test_forms',
        ...formDetails?.testers,
      },
    ]);
  }, [formDetails]);

  return (
    <div className='supervisor-settings pb-5'>
      <label className='d-flex align-items-center theme-font-inter-medium theme-text-black-1 theme-size-1_1 px-4 pt-3'>
        {formDetails?.title ?? ''}
      </label>
      {!selectedPermission ? (
        <PermissionsTable
          permissionList={permissionList}
          permissionRowClick={(row) => {
            setSelectedPermission(row);
          }}
        />
      ) : (
        <UpdatePermissions
          formId={formDetails._id}
          selectedPermission={selectedPermission}
          getFormDetail={getFormDetail}
          goBack={() => {
            setSelectedPermission(null);
          }}
        />
      )}
    </div>
  );
};

Permissions.propTypes = {
  formDetails: PropTypes.object,
  getFormDetail: PropTypes.func.isRequired,
};

export default Permissions;
