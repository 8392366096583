import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import crossIcon from 'assets/images/icons/cross.svg';
import plusIcon from 'assets/images/icons/plus.svg';
import FormButton from 'components/form-components/FormButton';
import ClickOutside from 'components/common-components/ClickOutside';
import { cloneDeep } from 'lodash';
import { CallAPI } from 'actions/General';
import { useTranslation } from 'react-i18next';

const ResponseSetListModal = ({
  selectResponseSet,
  closeModal,
  setShowResponseSetModal,
  listData,
  updateList,
  responseSetCreation,
}) => {
  const { t } = useTranslation();
  const [list, setList] = useState([]);

  useEffect(() => {
    setList(JSON.parse(listData).data);
  }, [listData]);

  const deleteResponseSet = async (deletedsetIndex) => {
    let updatedListData = cloneDeep(list);
    updatedListData.splice(deletedsetIndex, 1);
    await CallAPI(
      responseSetCreation ? 'ADD_ALL_RESPONSE_SET' : 'UPDATE_ALL_RESPONSE_SET',
      {
        choices: JSON.stringify({ data: updatedListData }),
      },
      null,
      null,
      null,
      null,
    );
    updateList();
  };

  return (
    <ClickOutside onClick={() => closeModal()}>
      <div className='response-set-list-modal'>
        <div className='close-btn' onClick={() => closeModal()}>
          <img src={crossIcon} />
        </div>
        <div className='response-set-list-modal-header'>
          <label className='mb-1'>{t('text_simple_choice_set')}</label>
        </div>
        <div className='response-set-list d-flex flex-column gap-3'>
          {list.map((set, index) => (
            <div key={index} className='d-flex gap-3 justify-content-between'>
              <div className='d-flex gap-2 flex-wrap'>
                {set.map((option, index) => (
                  <div
                    key={index}
                    className='response-set-item'
                    style={{ backgroundColor: `${option.itemColor}` }}
                  >
                    <span className='theme-size-0_9 theme-text-white-1'>{option.responseText}</span>
                  </div>
                ))}
              </div>
              <div className='d-flex gap-2'>
                <FormButton
                  text='button_delete'
                  variant='red-1'
                  minWidth
                  classes='px-2'
                  onClick={() => deleteResponseSet(index)}
                />
                <FormButton
                  text='button_select'
                  variant='green-1'
                  minWidth
                  classes='px-2'
                  onClick={() => selectResponseSet(set)}
                />
              </div>
            </div>
          ))}
        </div>

        <div className='response-set-list-modal-footer'>
          <label
            className='d-flex gap-1 align-items-center cursor-pointer action-label mt-1'
            onClick={() => setShowResponseSetModal(true)}
          >
            <img src={plusIcon} />
            <span className='theme-size-0_8 theme-text-green-1'>{t('text_add_response_set')}</span>
          </label>
        </div>
      </div>
    </ClickOutside>
  );
};

ResponseSetListModal.propTypes = {
  selectResponseSet: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  setShowResponseSetModal: PropTypes.func.isRequired,
  updateList: PropTypes.func.isRequired,
  listData: PropTypes.string.isRequired,
  responseSetCreation: PropTypes.bool.isRequired,
};

export default ResponseSetListModal;
