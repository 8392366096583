import { createSlice } from '@reduxjs/toolkit';

export const AuthReducer = createSlice({
  name: 'auth',
  initialState: {
    firebaseToken: null,
    authToken: null,
    refreshToken: null,
    companyPermissions: {},
    profile: null,
    totalCompanies: 0,
    hasUnsavedChanges: false,
    unsavedChangedType: null,
    triggerSaveFormChanges: { trigger: false },
    breadcrumbs: { icon: null, items: [] },
    systemModules: [],
    customizedTableHeader: {},
  },
  reducers: {
    updateAuthState: (state, action) => {
      let data = state;
      data = { ...data, ...action.payload };
      state.firebaseToken = data.firebaseToken;
      state.authToken = data.authToken;
      state.refreshToken = data.refreshToken;
      state.companyPermissions = data.companyPermissions;
      state.profile = data.profile;
      state.customizedTableHeader = {};
    },
    updateBreadCrumb: (state, action) => {
      state.breadcrumbs = action.payload.breadcrumbs;
    },
    updateSavedChanges: (state, action) => {
      state.hasUnsavedChanges = action.payload.hasUnsavedChanges;
      state.unsavedChangedType = action.payload.unsavedChangedType;
    },
    updateTriggerSaveFormChanges: (state, action) => {
      state.triggerSaveFormChanges = action.payload.triggerSaveFormChanges;
    },
    updateSystemModules: (state, action) => {
      state.systemModules = action.payload.systemModules;
    },
    updateTotalCompanies: (state, action) => {
      state.totalCompanies = action.payload.totalCompanies;
    },
    updateCustomizedTableHeader: (state, action) => {
      state.customizedTableHeader = {
        ...state.customizedTableHeader,
        [action.payload.type]: action.payload.customizedTableHeader,
      };
    },
  },
});
