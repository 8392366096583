import React from 'react';

import PropTypes from 'prop-types';
import MenuItems from "./MenuItems";

  
const Dropdown = ({ submenus, dropdown, icon, depthLevel, onItemClick }) => {
  depthLevel = depthLevel + 1;
  const dropdownClass = depthLevel > 1 ? "nested-dropdown-submenu" : "";

  return (
    <ul className={`nested-menu-dropdown ${dropdownClass} ${dropdown ? 'nested-menu-show ' : ''}`}>
      {submenus.map((submenu, index) => (
        <MenuItems item={submenu} key={index} depthLevel={depthLevel} icon={icon} onItemClick={onItemClick ? onItemClick : null}/>
      ))}
    </ul>
  );
};

  
  Dropdown.displayName = 'Dropdown';
  
  Dropdown.propTypes = {
    submenus: PropTypes.array.isRequired,
    dropdown: PropTypes.bool,
    icon: PropTypes.string,
    depthLevel:PropTypes.number, 
    onItemClick:PropTypes.func,
  };
  
  export default Dropdown;