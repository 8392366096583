import React, { useEffect, useState } from 'react';
import loginIcon from '../../../assets/images/attendance/log-in.svg';
import usersIcon from '../../../assets/images/attendance/users.svg';
import pauseIcon from '../../../assets/images/attendance/pause.svg';
import checkIcon from '../../../assets/images/attendance/circle-check.svg';
import inboxIcon from '../../../assets/images/attendance/inbox.svg';
import markerDefault from '../../../assets/images/attendance/marker-default.svg';

import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import StatsCard from './StatsCard';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';
import { CallAPI } from 'actions/General';

const AttendanceMap = ({ filterData, setLoader, setApiError }) => {
  const { t } = useTranslation();
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [mapRef, setMapRef] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [infoWindowData, setInfoWindowData] = useState(null);
  const [attendanceList, setAttendanceList] = useState([]);
  const [attendanceStats, setAttendanceStats] = useState({});

  const getAllAttendanceForMap = async () => {
    let result = await CallAPI(
      'GET_ATTENDANCE_FOR_MAP',
      { ...filterData },
      null,
      setLoader,
      setApiError,
    );
    if (result.status) {
      const attendance = result.data.attendance.map((singleAttendance) => ({
        id: singleAttendance._id,
        name: singleAttendance.firstName + ' ' + singleAttendance.lastName,
        latitude: singleAttendance.lastStartLat,
        longitude: singleAttendance.lastStartLng,
        icon: null,
      }));
      setAttendanceList(attendance);
    }
  };
  const getAllAttendanceStatsForMap = async () => {
    let result = await CallAPI(
      'FETCH_MAP_ATTENDANCE_STATS',
      { ...filterData },
      null,
      setLoader,
      setApiError,
    );
    if (result.status) {
      setAttendanceStats(result.data);
    }
  };
  useEffect(() => {
    getAllAttendanceStatsForMap();
    getAllAttendanceForMap();
  }, [filterData]);
  const { isLoaded } = useJsApiLoader({
    id: 'attendance-google-map',
    // eslint-disable-next-line no-undef
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
  });

  useEffect(() => {
    if (attendanceList.length > 0) {
      setMapCenter();
    }
  }, [attendanceList]);

  const setMapCenter = () => {
    let latSum = 0;
    let longSum = 0;

    for (let index = 0; index < attendanceList.length; index++) {
      latSum = latSum + attendanceList[index].latitude;
      longSum = longSum + attendanceList[index].longitude;
    }
    setCenter({ lat: latSum / attendanceList.length, lng: longSum / attendanceList.length });
  };

  const onMapLoad = (map) => {
    setMapRef(map);
  };

  const handleMarkerClick = (marker) => {
    mapRef?.panTo({ lat: marker.latitude, lng: marker.longitude });
    setInfoWindowData({ id: marker.id, address: marker.address });
    setIsOpen(true);
  };

  const closeInfoWindow = () => {
    setInfoWindowData(null);
    setIsOpen(false);
  };

  return (
    <div className='p-4'>
      {isLoaded && (
        <div className='map-container'>
          <GoogleMap
            id={'attendance-google-map'}
            mapContainerClassName='w-100 h-100'
            center={center}
            onLoad={onMapLoad}
            zoom={12}
            onClick={() => closeInfoWindow()}
          >
            {attendanceList.length > 0 &&
              attendanceList.map((marker, index) => {
                return (
                  <Marker
                    key={index}
                    icon={markerDefault}
                    position={{ lat: marker.latitude, lng: marker.longitude }}
                    onClick={() => handleMarkerClick(marker)}
                  >
                    {isOpen && infoWindowData?.id === marker.id && (
                      <InfoWindow onCloseClick={() => closeInfoWindow()}>
                        <label className='theme-size-0_8 theme-font-inter-semi-bold theme-text-black-1 pt-2 ps-1'>
                          {marker.name}
                        </label>
                      </InfoWindow>
                    )}
                  </Marker>
                );
              })}
          </GoogleMap>
        </div>
      )}
      <div className='mt-4'>
        <label className='theme-size-1_3 theme-font-inter-medium theme-text-black-1'>
          {t('text_attendance_stats')}
        </label>

        <div className='d-flex overflow-x-auto gap-3 mt-3 pb-1'>
          <StatsCard
            icon={usersIcon}
            title={'text_total_employees'}
            statNumber={attendanceStats.employeeCount ? attendanceStats.employeeCount : 0}
          />
          <StatsCard
            icon={loginIcon}
            title={'text_check_in'}
            statNumber={attendanceStats.checkedInCount ? attendanceStats.checkedInCount : 0}
          />
          <StatsCard
            icon={checkIcon}
            title={'text_check_out'}
            statNumber={attendanceStats.checkedOutCount ? attendanceStats.checkedOutCount : 0}
          />
          <StatsCard
            icon={pauseIcon}
            title={'text_on_break'}
            statNumber={attendanceStats.onBreakCount ? attendanceStats.onBreakCount : 0}
          />
          <StatsCard
            icon={inboxIcon}
            title={'text_pending_request'}
            statNumber={
              attendanceStats.pendingRequestsCount ? attendanceStats.pendingRequestsCount : 0
            }
          />
        </div>
      </div>
    </div>
  );
};
AttendanceMap.propTypes = {
  filterData: PropTypes.object.isRequired,
  setLoader: PropTypes.func.isRequired,
  setApiError: PropTypes.func.isRequired,
};
export default AttendanceMap;
