import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CloseButton, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import DropDownArrowGreen from 'assets/images/icons/dropdown-arrow-green.svg';
import personIcon from 'assets/images/icons/person.svg';
import Error from 'components/Error';
import FormButton from 'components/form-components/FormButton';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import PermissionField from 'components/form-components/PermissionField';
import { CallAPI } from 'actions/General';
import { getPermissionForAdmin } from 'config/permissionUtils';

const AddAdmin = ({ setPromoteAdmin, data, permission, userRole }) => {
  const auth = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const [values, setValues] = useState([]);
  const [loader, setLoader] = useState(false);
  const [APIError, setAPIError] = useState('');

  const handleChange = (key) => {
    let keyPart = key.split('.');
    let tempValues = [...values];
    let menuObject = tempValues.filter((permObject) => permObject.value === keyPart[0]);

    if (menuObject.length > 0) {
      let subMenuObject = menuObject[0].subMenu.filter(
        (permObject) => permObject.value === keyPart[1],
      );

      if (subMenuObject.length > 0) {
        let menuItem = subMenuObject[0].subMenu.filter(
          (permObject) => permObject.value === keyPart[2],
        );
        if (menuItem.length > 0) {
          menuItem[0].isChecked = !menuItem[0].isChecked;
        }
      }
    }

    setValues(tempValues);
  };
  const updatePermissions = async () => {
    let tempValues = [...values];
    let moduleList = [];

    let permissionList = auth.systemModules ? auth.systemModules : [];

    tempValues.map((module) => {
      let subModuleList = [];
      module.subMenu.map((subMenuObject) => {
        let operationList = [];

        subMenuObject.subMenu.map((subMenuItem) => {
          if (subMenuItem.isChecked) {
            operationList.push(subMenuItem.value);
          }
        });

        if (operationList.length > 0) {
          let subModuleObject = { levelName: subMenuObject.value, operations: operationList };
          subModuleList.push(subModuleObject);
        }
      });

      if (subModuleList.length > 0) {
        let PermissionObject = permissionList.filter(
          (permisson) => permisson.module === module.value,
        );

        if (PermissionObject.length > 0) {
          let ModuleObject = {
            adminModuleId: PermissionObject[0]._id,
            adminModuleName: module.value,
            moduleLevels: subModuleList,
          };
          moduleList.push(ModuleObject);
        }
      }
    });

    let APIData = {
      permissionId: permission ? permission._id : '',
      userId: data[0].id,
      modules: moduleList,
    };
    let result = await CallAPI('PROMOTE_ADMIN', APIData, null, setLoader, setAPIError, null);
    if (result.status) {
      await CallAPI(
        'UPDATE_USER_ROLE',
        {
          userId: data[0].id,
          userRole: 'admin',
        },
        null,
        setLoader,
        setAPIError,
        null,
      );

      setPromoteAdmin(false);
    }
  };
  useEffect(() => {
    let listValues = [];
    listValues = getPermissionForAdmin(
      auth.companyPermissions,
      permission && permission.modules ? permission.modules : [],
      true,
      false,
    );

    setValues(listValues);
  }, [auth]);

  return (
    <Modal
      show={true}
      contentClassName='alert-modal'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      backdrop='static'
      keyboard={false}
    >
      <div className='alert-content p-4 d-flex flex-column gap-4 justify-content-center align-items-center'>
        {APIError && <Error msg={APIError} />}
        <CloseButton onClick={() => setPromoteAdmin(false)} />
        <img src={personIcon} className='svg-black' style={{ height: '4rem' }} />
        {data.length > 0 && (
          <Fragment>
            {data.length > 1 ? (
              <div className='d-flex flex-column gap-2 w-100 align-items-center'>
                <label className='theme-text-black-1'>
                  {t('text_updating_permission_for')} ({data.length}) {t('text_users')}
                </label>
                <label className='theme-text-black-1 theme-size-1_2'>
                  {data
                    .slice(0, 2)
                    .map((user) => user.title)
                    .join(', ')}
                  {data.length > 2 ? ',...' : ''}
                </label>
              </div>
            ) : (
              <div className='d-flex flex-column gap-2 w-100 align-items-center'>
                <label className='theme-text-black-1'>
                  {t('text_updating_permission_for')} {t('user')}
                </label>
                <label className='theme-text-black-1 theme-size-1_2'>
                  {data[0].title} ({userRole || data[0].userRole})
                </label>
              </div>
            )}
          </Fragment>
        )}

        <PermissionField
          label='text_all_permissions'
          listValues={cloneDeep(values)}
          icon={DropDownArrowGreen}
          handleChange={(key) => handleChange(key)}
          classes={'border-grey-1-h1 px-4 justify-content-center'}
        />
        <div className='d-flex gap-3'>
          <FormButton
            text='button_cancel'
            variant={'white-1'}
            onClick={() => setPromoteAdmin(false)}
          />
          <FormLoadingButton
            loading={loader}
            text='button_save'
            variant={'green-1'}
            onClick={updatePermissions}
          />
        </div>
      </div>
    </Modal>
  );
};
AddAdmin.propTypes = {
  setPromoteAdmin: PropTypes.func,
  permission: PropTypes.object,
  userRole: PropTypes.string,
  data: PropTypes.array,
};
export default AddAdmin;
