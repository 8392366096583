import * as yup from 'yup';
import sa from '../../assets/images/icons/flags/sa.svg';
import pk from '../../assets/images/icons/flags/pk.svg';
import * as firebase from '../../config/fbConfig';
import { signInWithEmailAndPassword, signInWithPhoneNumber } from 'firebase/auth';
import { AuthReducer } from '../../redux/auth';
import store from '../../store/store';
import { CallAPI } from '../../actions/General';
import NodeRSA from 'node-rsa';
const AuthAction = AuthReducer.actions;
const dispatch = store.dispatch;

export const loginSchema = yup.object({
  countryCode: yup.string().required('Country code is required'),
  mobileNumber: yup.string().required('Phone number is required'),
  password: yup.string().required('Password is required'),
});

export const registerSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup.string().email().required('Email address is required'),
  countryCode: yup.string().required('Country code is required'),
  mobileNumber: yup.string().required('Phone number is required'),
  password: yup.string().required('Password is required').min(8),
  check: yup.boolean().isTrue('Mark check terms and conditions'),
});

export const otpSchema = yup.object({
  otp: yup.string().required('OTP is required').length(6),
});

export const resetPasswordSchema = yup.object({
  countryCode: yup.string().required('Country code is required'),
  mobileNumber: yup.string().required('Phone number is required'),
});

export const newPasswordSchema = yup.object({
  password: yup.string().required('Password is required').min(8),
  rePassword: yup
    .string()
    .required('Password is required')
    .min(8)
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

export const countryList = [
  {
    value: '+966',
    icon: sa,
    displayValue: 'select_saudi_arabia',
  },
  {
    value: '+92',
    icon: pk,
    displayValue: 'select_pakistan',
  },
];

export const userLogin = async (data, setApiError, navigate) => {
  await signInWithEmailAndPassword(firebase.auth, data.email, data.password)
    .then((res) => {
      dispatch(AuthAction.updateAuthState({ firebaseToken: res.user.accessToken }));
      // return { status: 1, data: {} };
      navigate('/all_companies');
    })
    .catch(async (error) => {
      if (error.code === 'auth/invalid-login-credentials') {
        setApiError('Phone or password is invalid');
        // return { status: 0, message: 'Phone or password is invalid' };
      }
    });
};
export const signInWithPhone = async (
  data,
  setConfirmationResultState,
  setApiError,
  calledFrom,
  setStep = undefined,
) => {
  const email = data.phone + process.env.REACT_APP_EMAIL_SUFFIX;

  let result = await CallAPI('CHECK_USER_EXIST', { emailId: email }, null, null, setApiError, null);
  let isSuccess = calledFrom === 'register' ? result.data.status : !result.data.status;
  if (isSuccess) {
    await signInWithPhoneNumber(firebase.auth, data.phone, data.verifier)
      .then((confirmationResult) => {
        setConfirmationResultState(confirmationResult);
        if (setStep) setStep(2);
      })
      .catch(async (error) => {
        if (error.code === 'auth/invalid-phone-number') setApiError('invalid phone number');
        else setApiError(error.message);
      });
  } else {
    // setApiError(result.data.msg);
    if (calledFrom === 'register') setApiError('user already exist');
    else setApiError('user does not exist');
  }
};
export const linkAccount = async (data, confirmationResultState, setApiError, navigate) => {
  await confirmationResultState
    .confirm(data.otp)
    .then(async (res) => {
      dispatch(AuthAction.updateAuthState({ firebaseToken: res.user.accessToken }));
      let result = await CallAPI(
        'REGISTER_USER',
        {
          firstName: data.firstName,
          lastName: data.lastName,
          password: await encryptPassword(data.password),
          countryCode: data.countryCode,
          mobileNumber: data.mobileNumber,
        },
        null,
        null,
        setApiError,
        null,
      );
      if (result.status)
        navigate('/add_company', {
          relative: 'path',
          state: { email: data.email },
        });
    })
    .catch(async (error) => {
      if (error.code === 'auth/invalid-verification-code') setApiError('code is not valid');
    });
};

export const encryptPassword = async (password) => {
  // decoding base64 public key
  const SERVER_PUBLIC_KEY = atob(process.env.REACT_APP_SERVER_PUBLIC_KEY);
  try {
    const publicKey = new NodeRSA(SERVER_PUBLIC_KEY, 'pkcs1-public', {
      encryptionScheme: {
        hash: 'sha1',
      },
    });

    const encryptedData = publicKey.encrypt(password, 'base64');
    return encryptedData;
  } catch (err) {
    console.log('Encryption failed', err);
  }
};
