import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import arrowDownIcon from 'assets/images/icons/dropdown-arrow-grey.svg';
import Error from 'components/Error';
import FormButton from 'components/form-components/FormButton';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import SelectField from 'components/form-components/SelectField';
import { CallAPI } from 'actions/General';
import { businessesList, employees } from 'pages/company/utils';
import { isPermissionAvailable } from 'config/permissionUtils';
import { permissionKeys } from 'config/config';

const AdditionalDetails = ({ companyDetails }) => {
  const [values, setValues] = useState({ businessIndustry: '', employeeRange: '' });
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState('');

  useEffect(() => {
    var businessArray = JSON.parse(companyDetails?.businessIndustry);
    setValues({
      businessIndustry: businessArray[0],
      employeeRange: companyDetails?.employeeRange || '',
    });
  }, [companyDetails]);

  const handleChange = async (field, value) => {
    setValues({ ...values, [field]: value });
  };

  const submit = async () => {
    await CallAPI(
      'UPDATE_COMPANY_DETAIL',
      {
        businessIndustry: `["${values.businessIndustry}"]`,
        employeeRange: values.employeeRange,
      },
      null,
      setLoading,
      setApiError,
    );
  };

  return (
    <>
      <div className='px-4 py-3'>
        {apiError && (
          <div className='mb-2'>
            <Error msg={apiError} />
          </div>
        )}
        <SelectField
          placeholder='field_business_industry'
          label={
            values.businessIndustry
              ? businessesList.find((b) => b.value === values.businessIndustry)?.displayName || ''
              : 'field_business_industry'
          }
          listValues={businessesList.map((e) => ({ value: e.value, displayValue: e.displayName }))}
          icon={arrowDownIcon}
          handleChange={handleChange}
          name={'businessIndustry'}
          isAutoClose={true}
          variant='white-1'
          menuClasses={'w-100'}
          isHorizontal
        />

        <SelectField
          placeholder='field_employee_range'
          label={
            values.employeeRange
              ? employees.find((b) => b.value === values.employeeRange)?.displayName
              : 'field_employee_range'
          }
          listValues={employees.map((e) => ({ value: e.value, displayValue: e.displayName }))}
          icon={arrowDownIcon}
          handleChange={handleChange}
          name={'employeeRange'}
          isAutoClose={true}
          variant='white-1'
          classes='mt-3'
          menuClasses={'w-100'}
          isHorizontal
        />
        {isPermissionAvailable(permissionKeys.system_company_setup_update) && (
          <div className='mt-3  w-100 d-flex justify-content-end gap-3'>
            <FormButton text='button_cancel' variant='white-1' />
            <FormLoadingButton
              onClick={submit}
              loading={loading}
              text='button_save'
              variant='green-1'
            />
          </div>
        )}
      </div>
    </>
  );
};

AdditionalDetails.propTypes = {
  companyDetails: PropTypes.object,
};

export default AdditionalDetails;
