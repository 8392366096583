import React from 'react';
import PropTypes from 'prop-types';
import selectArrow from 'assets/images/icons/arrow-down.svg';
import Error from 'components/Error';
import SelectField from 'components/form-components/SelectField';
import SelectIconField from 'components/form-components/SelectIconField';
import TextArea from 'components/form-components/TextArea';
import TextField from 'components/form-components/TextField';

function Form({ values, errors, touched, handleChange, APIError }) {
  const textDirectionValues = [
    { value: 'left_to_right', displayValue: 'select_left_to_right' },
    { value: 'right_to_left', displayValue: 'select_right_to_left' },
  ];

  const textDirectionValuesMap = Object.fromEntries(
    textDirectionValues.map((v) => [v.value, v.displayValue]),
  );

  return (
    <div className='detail'>
      {APIError && <Error msg={APIError} />}
      <TextField
        name='title'
        displayName='field_screen_title'
        placeholder='field_screen_title'
        value={values.title}
        error={errors.title}
        touched={touched.title}
        handleChange={handleChange}
        classes='mb-3'
        isHorizontal={true}
        shrinkFieldOnly
        horizontalLabelClass={'min-width-176'}
      />

      <SelectIconField
        handleChange={handleChange}
        name='formIcon'
        placeholder='field_select_icon'
        label={values.formIcon}
        icon={selectArrow}
        variant='white-1'
        isAutoClose={true}
        isHorizontal={true}
        iconType='form'
      />
      <div className='space-4'></div>
      <SelectField
        name='textDirection'
        handleChange={handleChange}
        listValues={textDirectionValues}
        placeholder='field_form_direction'
        label={textDirectionValuesMap[values.textDirection] ?? 'select_select'}
        icon={selectArrow}
        variant='white-1'
        menuClasses='w-100'
        isAutoClose={true}
        isHorizontal={true}
      />
      <div className='space-4'></div>
      <TextArea
        name='description'
        displayName='field_description'
        value={values.description}
        handleChange={handleChange}
        classes='mb-3'
        isHorizontal={true}
        rows={3}
        horizontalLabelClass={'min-width-176'}
      />
    </div>
  );
}
Form.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  APIError: PropTypes.string.isRequired,
};
export default Form;
